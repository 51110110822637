import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Card, CardHeader, CardBody } from "react-bootstrap"
import { ListsWidget9 } from "../widgets"
import { NavLink, Link } from "react-router-dom"

import OrderList from "../../../app/modules/Order/OrderList"
import { Modal, Button } from "react-bootstrap"
import "../dashboards/styles/dashboard.css"
import { userContext } from "../../layout/_core/MetronicUserLoginProvider"
import Videoembed from "./Videoembed"
import "./styles/userGuidePopup.css"
import ChooseOptions from "../../../app/modules/Specifications/Options/ChooseOption"
import { useSelector, shallowEqual } from "react-redux"

export function Demo1Dashboard(props) {
  const { firstTimeUserLogin, setFirstTimeUserLogin } = useContext(userContext)

  const [showModal, setShowModal] = useState(true)

  const [userGuideModalShow, setUserGuideModalShow] = useState(false)
  const [startHereVideoModal, setStartHereVideoModal] = useState(false)

  const [currentGuide, setCurrentGuide] = useState(false)
  const [showFreeEditingModal, setShowFreeEditingModal] = useState(false);
  const user = useSelector(state => state.auth.user, shallowEqual)


  let next = 1
  let previous = 0
  let guidePopupData = [
    {
      id: 1,
      content: (
        <div className="inger_box">
          <img
            src="media/userGuide/logo.png"
            className="img-fluid"
            alt="loog"
          />
          <p>Welcome to Pixhoto</p>
          <p>Let’s Show you how to use the platform in a few simple steps</p>
          <a href="javascript:void(0)" onClick={nextGuide} className="next">
            Next
          </a>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div className="inger_box">
          <p>There are 2 main elements of the pixhoto platform</p>
          <div className="destho">
            <ul>
              <li>
                <a href="#">
                  <span>
                    <img
                      src="/media/userGuide/icon.png"
                      className="img-fluid"
                      alt="icon"
                    />
                  </span>{" "}
                  Dashboard
                </a>
              </li>
              <li className="twbo">
                <a href="#">
                  <span>
                    <img
                      src="/media/userGuide/iconw1.png"
                      className="img-fluid"
                      alt="icon"
                    />
                  </span>
                  Specifications
                </a>
                <a href="#">
                  <span>
                    <img
                      src="/media/userGuide/iconw2.png"
                      className="img-fluid"
                      alt="icon"
                    />
                  </span>{" "}
                  Orders
                </a>
              </li>
              <li className="grde">Account</li>
              <li>
                <a href="#">
                  <span>
                    <img
                      src="/media/userGuide/icon1.png"
                      className="img-fluid"
                      alt="icon"
                    />
                  </span>{" "}
                  User Profile
                </a>
              </li>

              <li className="grde">Tools</li>
            </ul>
          </div>
          <div className="butondd">
            <a
              href="javascript:void(0)"
              onClick={previousGuide}
              className="back"
            >
              Back
            </a>
            <a href="javascript:void(0)" onClick={nextGuide} className="next">
              Next
            </a>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      content: (
        <div className="inger_box">
          <h2>Specifications</h2>
          <p>
            Specifications are the set of instuctions that allow our editors to
            know exactly what treatment you want for your images.
          </p>
          <img src="/media/userGuide/imges.png" className="img-fluid" />
          <p>
            All specifications are saved for future use.
            <br /> Every unique task requires you to set a new specificaion.
            <br /> A new specification can be set in under 60 seconds
          </p>
          <div className="butondd">
            <a
              href="javascript:void(0)"
              onClick={previousGuide}
              className="back"
            >
              Back
            </a>
            <a href="javascript:void(0)" onClick={nextGuide} className="next">
              Next
            </a>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      content: (
        <div className="inger_box">
          <h2>Orders</h2>
          <p>
            Once a specification has been set, you can now place the order
            <br /> by uploading the images to be edited and publish the order.
          </p>
          <img src="media/userGuide/orderimg.png" className="img-fluid" />
          <p>
            Once your order is received, our editors will start working
            <br /> and deliver your images within 24 hours (or your selected
            timeline).
          </p>
          <div className="butondd">
            <a
              href="javascript:void(0)"
              onClick={previousGuide}
              className="back"
            >
              Back
            </a>

            <a
              href="javascript:void(0)"
              onClick={() => setStartHereVideoModal(true)}
              className="demo"
            >
              Watch Demo Video
            </a>

            <NavLink className="start" to="/specifications/options">
              {/* <a href="javascript:void(0)"  className="start"> */}
              Start Here
              {/* </a> */}
            </NavLink>
          </div>
        </div>
      ),
    },
  ]
  function userGuide() {
    setCurrentGuide(guidePopupData[0])
    setUserGuideModalShow(true)
  }
  function nextGuide() {
    setTimeout(() => {
      if (next >= guidePopupData.length) {
        setUserGuideModalShow(false)
      } else {
        previous = next
        setCurrentGuide(guidePopupData[next++])
      }
    }, 100)
  }
  function previousGuide() {
    next--
    previous--
    setCurrentGuide(guidePopupData[previous])
  }
  function handleClose() {
    setFirstTimeUserLogin(false) //once you close modal, set user loggedin first time to false
    setShowModal(false)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has("show_guide")) {
      userGuide()
    }
    if (user.trialImages != 0 && user.trialImages > 0 && firstTimeUserLogin) {
      setShowFreeEditingModal(true);
    }
  }, [])
  return (
    <>
      <Row
        className={`card-custom gutter-b paddrig`}
        style={{ height: "100%", boxSizing: "border-box" }}
      >
        <Col lg={12} style={{ height: "100%", boxSizing: "border-box" }}>

          <Card className={`card-custom gutter-b`} style={{ padding: "2rem", height: "100%" }}>
            <Card.Header className={`py-5 mider`}>
              <h2 className="card-title align-items-right  flex-column">
                <span className="card-label font-weight-bolder text-dark" style={{ 'display': 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Welcome to Pixhoto</span>
                <div style={{ 'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'opacity': '70%' }}>
                  <span className="mt-2 font-weight-bold font-size-sm" >
                    <h5 style={{ 'opacity': '70%', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Set your editing specificaion for our retouchers here in a few simple steps,</h5>
                    <h5 style={{ 'opacity': '70%', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center' }}>upload your images and get them delivered in less than 24 hours.</h5>
                  </span>
                </div>
              </h2>
              <div className="card-toolbar buttonree">
                <a href="https://pixhoto.com/contact-us/" target="_blank" className="btn btn-light font-weight-bolder font-size-sm mr-3 float-right">
                  Need help? Contact Us.
                </a>
              </div>
            </Card.Header>

            <Card.Body style={{ display: "flex", alignItems: "center", }}>
              <div style={{ width: "100%", gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))", }} className="dt_box">


                <div style={{ height: '0%', display: 'flex', 'justify-content': 'center', background: 'rgb(239 241 251)', width: '100%' }}>

                  <div className="row justify-content-around" style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>

                    <div className="col-6" >
                      <Link to="/specifications/guided">
                        <div className="card  m-auto" style={{ cursor: "pointer" }}>
                          <div style={{ background: 'rgb(239 241 251)' }}>
                            <img src="/media/users/step by step final (1).png" alt="image" className="w-100 rounded" style={{ 'margin-top': '10% ' }} />
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6">
                      <div style={{ 'width': '85%', 'text-align': 'justify', 'margin': '0 auto', 'margin-left': '18px' }}>
                        <h1 className="card-label font-weight-bolder text-dark" style={{ 'display': 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Set Image Specifications</h1>
                        <h5 style={{ 'opacity': '70%', 'text-align': 'center', 'font-size': 'large' }}>Pick and choose from convenient editing options based on your product category</h5>
                        <Link to="/specifications/guided">
                          <Button className="contain btnthemecolor" style={{ 'margin-top': '4%', 'margin-left': '27%' }} size="lg">
                            Get started
                          </Button>
                        </Link>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="mt-4 font-weight-bold font-size-sm" style={{ fontSize: "1.3rem", 'margin-left': '8rem' }}>
                  <p style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Or if you need your images edited for popular marketplace platforms like Amazon, Etsy etc. ,</p>
                  <h4 style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Click Below</h4>
                  <div style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>
                    <Link to="/specifications/marketplaces">
                      <Button className="m-2 contain btnthemecolor" size="sm">
                        Marketplaces
                      </Button>
                    </Link>
                  </div>
                </div>



                <Card className="dlierss">
                  <div>
                    <img alt="Gift" src="/media/general/gift.jpeg"></img>
                    Your first 10 images will be edited for free.
                  </div>
                </Card>

              </div>
            </Card.Body>
          </Card>

          {/* Quick Videos */}
          <Card className={`d-none d-lg-block card-custom gutter-b card w-100 card h-54`}
          >
            <Card.Header className={`py-5`}>
              <h3 className="card-title align-items-start flex-column">
                <span
                  className="card-label font-weight-bolder text-dark"
                  style={{ fontSize: "2.2rem" }}
                >
                  Quick Videos
                </span>
                <span
                  className="text-muted mt-3 font-weight-bold font-size-sm"
                  style={{ fontSize: "1.3rem" }}
                >
                  Introductory demo videos to get you started.
                </span>
              </h3>
              <div>
                <Link to="/view-all-videos">

                  <button
                    className="btn btn-light"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    View All Videos
                  </button>
                </Link>
              </div>
            </Card.Header>
            <Card.Body
              style={{
                display: "flex",
                alignItems: "center",
                gridAutoFlow: "column",
                gridColumnGap: "27px",
                justifyContent: "center",
              }}
              className="quickvideocard"
            >
              <div>
                <Videoembed embedId="3Iw-R5adUPM" key={1} />
                <p>1. Introduction</p>
              </div>

              <div>
                <Videoembed embedId="l2FYjKwCgK8" key={2} />
                <p>2. Step By Step</p>
              </div>

              <div>
                <Videoembed embedId="7PM7xP85m_8" key={3} />
                <p>3. Marketplace</p>
              </div>

              <div>
                <Videoembed embedId="omDIRfJ96fI" key={4} />

                <p>4. Place Order</p>
              </div>

              <div>
                <Videoembed embedId="fTgWqyHqH1A" key={5} />

                <p>5. Colour Fix</p>
              </div>

              {/* <div>
              <Videoembed embedId="XZe3yzxyaRg" key={6} />
                <p>1. Introduction</p>
              </div> */}
            </Card.Body>
          </Card>

          {/* Completed orders */}
          {/* <Card className={`d-none d-lg-block card-custom gutter-b card w-100 card h-54`}>
            <OrderList hideCreate={true} />
          </Card> */}

          {/* Quick Videos */}
          {/* <Card className={`d-none d-lg-block card-custom gutter-b card w-100 card h-54`}>
            <Card.Header className={`py-5`} style={{ padding: "0 1rem" }}>
              <h3 className="card-title align-items-start flex-column">
                <span
                  className="card-label font-weight-bolder text-dark"
                  style={{ fontSize: "2.2rem", marginLeft: "2rem" }}
                >
                  Quick Videos
                </span>
              </h3>
            </Card.Header>
            <Card.Body>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr ",
                  columnGap: "1.5rem",
                  rowGap: "1.5rem",
                  justifyContent: "center",
                }}
              >
                <Card>
                  <Card.Header className="text-center" style={{ height: "6rem" }}>
                    <span style={{ fontSize: "1.2rem" }}>Product demo</span>
                  </Card.Header>
                  <Videoembed embedId="3Iw-R5adUPM" key={1} />
                </Card>

                <Card>
                  <Card.Header className="text-center" style={{ height: "6rem" }}>
                    <span style={{ fontSize: "1.2rem" }}>
                      {" "}
                      How to set Specification Step by step
                    </span>
                  </Card.Header>
                  <Videoembed embedId="l2FYjKwCgK8" key={2} />
                </Card>

                <Card>
                  <Card.Header className="text-center" style={{ height: "6rem" }}>
                    <span style={{ fontSize: "1.2rem" }}>
                      {" "}
                      How to set Specification Marketplace
                    </span>
                  </Card.Header>
                  <Videoembed embedId="7PM7xP85m_8" key={3} />
                </Card>

                <Card>
                  <Card.Header className="text-center" style={{ height: "6rem" }}>
                    <span style={{ fontSize: "1.2rem" }}>
                      {" "}
                      How to place an Order
                    </span>
                  </Card.Header>
                  <Videoembed embedId="omDIRfJ96fI" key={4} />
                </Card>
                <Card>
                  <Card.Header className="text-center" style={{ height: "6rem" }}>
                    <span style={{ fontSize: "1.2rem" }}>
                      {" "}
                      How to place an Order with Invisible Mannequin
                    </span>
                  </Card.Header>
                  <Videoembed embedId="fTgWqyHqH1A" key={5} />
                </Card>
                <Card>
                  <Card.Header className="text-center" style={{ height: "6rem" }}>
                    <span style={{ fontSize: "1.2rem" }}>
                      {" "}
                      How to place an Order with Colour-Fix
                    </span>
                  </Card.Header>
                  <Videoembed embedId="XZe3yzxyaRg" key={6} />
                </Card>
              </div>
            </Card.Body>
          </Card> */}

          {/* Colourways */}
          {/* <Card className={`d-none d-lg-block card-custom gutter-b card w-100 card h-54`}>
            <Card.Body className="colourways-card">
              <div className="p_rt"
              >
                <div className="col8">
                
                  <h2>
                    Colourways
                  </h2>
                  <p>
                    One Product in Multiple Colours?
                  </p>
                  <p >
                  Colourways, enables you to showcase your products in all available colours. You need to send us the base image and colour code (RGB/Hex) or reference image.
                  </p>
                
                  <p >
                    Colourways option is available in the Order 
                    Section, where you can provide the 
                    relevent colour code or colour swatches
                    and assign them to your product images.
                    <br />
                    Cost per colour edit: CA$ 1.1/ US$ 0.90
                </p>
                </div>
                <div className="col4">
                <img
                  src="/media/home/dashboardColourways.jpg"
                  alt="img"

                  className="img-fluid"
                ></img>
                </div>
                

              </div>
             
            </Card.Body>
          </Card> */}

          {/* Wrapping */}
          {/* <Card className={`d-none d-lg-block card-custom gutter-b card w-100 card h-54`}>
            <Card.Body>
              <div
                className="p_rt"
              >
                <div className="col8">
                <img
                  src="/media/home/dashboardWrapping.jpg"
                  alt="img"
                  style={{
                    objectFit: "contain",
                    // width: "15rem",
                    height: "10rem",
                    // backgroundColor: "rgb(226,227,228)",
                    // marginTop: "3rem",
                  }}
                ></img>
                
                  
                </div>
                <div className="col4">
                <h2
                    // className="card-label font-weight-bolder text-dark"
                    // style={{ fontSize: "2rem" }}
                  >
                    Wrapping
                  </h2>
                 <p>
                   Products in Multiple Prints/Textures/Fabrics?
                 </p>
                  <span className="card-text">
                    Send us the base image of your product and the
                     scanned fabric samples and we can wrap all
                    
                    properties of the fabric and bring all variations of products
                    to life.
                  </span>
                  <p >
                  <span className="card-text">
                    To help us understand your product wrapping
                    
                    requirements we suggest you to reachout to
                    
                    us at
                    <br/>
                    
                    <strong style={{ fontSize: "1.5rem" }}>cs@pixhoto.com</strong>
                    &nbsp;&nbsp; or
                    <br/>
                    <a
                      onClick={() =>
                        window.open(
                          "https://calendly.com/pixhoto",
                          "Popup",
                          "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400"
                        )
                      }
                      target="_parent"
                      href="javascript: void(0)"
                    >
                      <u>book a slot here</u>
                    </a>
                    <br />
                    and we will connect with you
                  </span>
                </p>
               
                </div>
                
              </div>
            </Card.Body>
          </Card> */}

          {/* Feedback */}
          {/* <Card className={`d-none d-lg-block card-custom gutter-b card w-100 card h-54`}>
            <Card.Header className={`py-5`} style={{ padding: "0 1rem" }}>
              <h3 className="card-title align-items-start flex-column">
                <span
                  className="card-label font-weight-bolder text-dark"
                  style={{ fontSize: "2.2rem", marginLeft: "1rem" }}
                >
                  Feedback
                </span>
              </h3>
            </Card.Header>
            <Card.Body>
              <span style={{}}>
                Share your feed back on the Platform,ease of use and overall
                experience here!
              </span>
              <br />
              <br />
              <a
                onClick={() =>
                  window.open(
                    "https://form.jotform.me/212414630742447",
                    "Popup",
                    "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400"
                  )
                }
                target="_parent"
                href="javascript: void(0)"
              >
                <Button variant="info">Platform Feedback</Button>
              </a>
            </Card.Body>
          </Card> */}
        </Col>

        {/* Platform feature list */}
        {/* <Col lg={3} style={{ height: "", postion: "absolute" }}>
          <ListsWidget9 />
        </Col> */}

        {/* Display tutorial modal when user logs in for the first time */}
        {/* {firstTimeUserLogin && (
          <Modal
            show={showModal}
            onHide={handleClose}
            dialogClassName="dashboard-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Platform Introduction Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ width: "70vw", height: "100%" }}>
                <iframe
                  style={{ width: "100%", height: "100%" }}
                  src="https://www.youtube.com/embed/3Iw-R5adUPM"
                >
                  {" "}
                </iframe>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
             
            </Modal.Footer>
          </Modal>
        )} */}
      </Row>

      <Modal
        show={userGuideModalShow}
        onHide={() => setUserGuideModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>{currentGuide.content}</Modal.Body>
      </Modal>

      <Modal
        show={startHereVideoModal}
        onHide={() => setStartHereVideoModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Platform Introduction Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Videoembed embedId="3Iw-R5adUPM" />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-light-primary font-weight-bolder font-size-sm "
            variant="secondary"
            onClick={() => setStartHereVideoModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* free editing images modal */}
      <Modal
        show={showFreeEditingModal}
        onHide={() => setShowFreeEditingModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Platform Introduction Video</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <img style={{ width: "100%" }} src="/media/general/free-editing.png" />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-light-primary font-weight-bolder font-size-sm "
            variant="secondary"
            onClick={() => setShowFreeEditingModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
