import React from "react"
import "./landingPage.css"
import "bootstrap"
import Carousel from "react-gallery-carousel"
import "react-gallery-carousel/dist/index.css"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import jQuery from "jquery";
// import $ from "jquery";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LandingPage = () => {
  const [open, setOpen] = React.useState(false);
  // const 
  const initialValues = {
    name: "",
    email: "",
    imagesQuantity: "",
    comments: "",
  }

  React.useEffect(() => {
    setTimeout(() => {
      let scrollFlag = false;
      window.addEventListener('scroll', () => {
        const numberContainer = document.getElementById("scrollPos");
        const elementPosition = numberContainer.offsetTop;

        const scrolled = window.scrollY;
        if (scrolled >= elementPosition && scrollFlag === false) {
          scrollFlag = true;
          console.log("scrolled");
          (function ($) {
            $.fn.countTo = function (options) {
              options = options || {};

              return $(this).each(function () {
                // set options for current element
                var settings = $.extend({}, $.fn.countTo.defaults, {
                  from: $(this).data('from'),
                  to: $(this).data('to'),
                  speed: $(this).data('speed'),
                  refreshInterval: $(this).data('refresh-interval'),
                  decimals: $(this).data('decimals')
                }, options);

                // how many times to update the value, and how much to increment the value on each update
                var loops = Math.ceil(settings.speed / settings.refreshInterval),
                  increment = (settings.to - settings.from) / loops;

                // references & variables that will change with each update
                var self = this,
                  $self = $(this),
                  loopCount = 0,
                  value = settings.from,
                  data = $self.data('countTo') || {};

                $self.data('countTo', data);

                // if an existing interval can be found, clear it first
                if (data.interval) {
                  clearInterval(data.interval);
                }
                data.interval = setInterval(updateTimer, settings.refreshInterval);

                // initialize the element with the starting value
                render(value);

                function updateTimer() {
                  value += increment;
                  loopCount++;

                  render(value);

                  if (typeof (settings.onUpdate) == 'function') {
                    settings.onUpdate.call(self, value);
                  }

                  if (loopCount >= loops) {
                    // remove the interval
                    $self.removeData('countTo');
                    clearInterval(data.interval);
                    value = settings.to;

                    if (typeof (settings.onComplete) == 'function') {
                      settings.onComplete.call(self, value);
                    }
                  }
                }

                function render(value) {
                  var formattedValue = settings.formatter.call(self, value, settings);
                  $self.html(formattedValue);
                }
              });
            };

            $.fn.countTo.defaults = {
              from: 0,               // the number the element should start at
              to: 0,                 // the number the element should end at
              speed: 1000,           // how long it should take to count between the target numbers
              refreshInterval: 100,  // how often the element should be updated
              decimals: 0,           // the number of decimal places to show
              formatter: formatter,  // handler for formatting the value before rendering
              onUpdate: null,        // callback method for every time the element is updated
              onComplete: null       // callback method for when the element finishes updating
            };

            function formatter(value, settings) {
              return value.toFixed(settings.decimals);
            }
          }(jQuery));

          jQuery(function ($) {
            // custom formatting example
            $('.count-number').data('countToOptions', {
              formatter: function (value, options) {
                return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
              }
            });

            // start all the timers
            $('.timer').each(count);

            function count(options) {
              var $this = $(this);
              options = $.extend({}, options || {}, $this.data('countToOptions') || {});
              $this.countTo(options);
            }
          });
        }
      })
    }, 500);

    return () => {
      window.removeEventListener('scroll', () => {})
    }

  }, [])

  const ContactFormSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").matches(/^[a-zA-Z ]*$/, 'Only alphabets are allowed'),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        'Email is required'
      ),
    imagesQuantity: Yup.string()
      .required(
        'images Quantity is required'
      ),
    comments: Yup.string()
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ContactFormSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      try {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 5000);
        console.log({ values })
        sendEmail(values);
      } catch (err) {
        console.log(err)
      }
    },
  });


  const sendEmail = (formData) => {
    /* SmtpJS.com - v3.0.0 */
    const Email = {
      send: function (a) {
        return (new Promise(function (n, e) {
          (a.nocache = Math.floor(1e6 * Math.random() + 1));
          (a.Action = "Send");
          var t = JSON.stringify(a);
          Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
            n(e);
          });
        }));
      },
      ajaxPost: function (e, n, t) {
        var a = Email.createCORSRequest("POST", e);
        a.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        (a.onload = function () {
          var e = a.responseText;
          null != t && t(e);
        });
        a.send(n);
      },
      ajax: function (e, n) {
        var t = Email.createCORSRequest("GET", e);
        (t.onload = function () {
          var e = t.responseText;
          null != n && n(e);
        });
        t.send();
      },
      createCORSRequest: function (e, n) {
        var t = new XMLHttpRequest();
        return (
          "withCredentials" in t
            ? t.open(e, n, !0)
            : "undefined" != typeof XDomainRequest
              ? (t = null)
              : (t = null),
          t
        );
      }
    };

    Email.send({
      SecureToken: "adc0bbc8-6eb3-4ea1-b8ba-531ad46dfba8",
      To: 'cs@pixhoto.com',
      From: "cs@pixhoto.com",
      Subject: "Contact Form",
      Body: `Name: ${formData.name} <br> Email: ${formData.email} <br> Images Quantity: ${formData.imagesQuantity} <br> Comments: ${formData.comments}`,
    }).then(
      message => console.log('sent',message)
    );

    formik.resetForm();
    window.scrollTo(0, 0);

  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ContentProps={{
          sx: {
            height: "10rem",
          }
        }}
        autoHideDuration={5000}
        open={open}
        severity="success"
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Thank you for contacting us ! We will get back to you soon
        </Alert>

      </Snackbar>

      {/* <!--header--> */}
      <header className="top_menu">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg navbar-light ">
                <a className="navbar-brand" href="#">
                  <img
                    src="media/landing/logo.svg"
                    className="img-fluid"
                    alt="logo"
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="#form_section">
                        Large Volumes? Contact Us.{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to="/auth/login">
                        <a className="nav-link" href="#">
                          Login
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/auth/registration">

                        <a className="nav-link" href="#">
                          Signup
                        </a>
                      </Link>

                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!--End-header--> */}

      {/* <!--top_banner--> */}
      <section id="top_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Fast, Affordable, & Reliable Retouching By Pixhoto</h1>
              <p>
                Pixhoto is a retouching service provider that enables photo
                editing professionals get exceptional retouching photo output
                quality at most affordable prices.{" "}
                <u>Retouched by real humans.</u>
              </p>
              <Link to="/auth/registration">

                Get First 10 Photos For Free
              </Link>
              <h4>Limited period offer. Guaranteed high quality output.</h4>
            </div>
            <div className="col-lg-6">
              <div className="vodeli">
                <video controls autoPlay={true} muted={true}>
                  <source src="media/landing/video.mp4" type="video/mp4" />
                  <source src="media/landing/video.mp4" type="video/ogg" />
                </video>

                <h5>
                  <img src="media/misc/rew.svg" className="img-fluid" />
                  <img src="media/misc/rew.svg" className="img-fluid" />
                  <img src="media/misc/rew.svg" className="img-fluid" />
                  <img src="media/misc/rew.svg" className="img-fluid" />
                  <img src="media/misc/rew.svg" className="img-fluid" />
                </h5>


                <h3>
                  "Pixhoto took the headache out of image editing, which used to be a problematic and cumbersome process in the past." <br />
                  - Rob Cohen, Owner, Product Photography Studio
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="trusted">
                <h6>Pixhoto Is Trusted By</h6>
                <img
                  src="media/landing/trusted.png"
                  className="img-fluid"
                  alt="trusted"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-top_banner--> */}

      {/* <!--retouching_section--> */}
      <section id="retouching_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head">
                <h1>Before-After Retouching Samples</h1>
                <p>
                  Specialization across e-commerce, fashion, jewelry, real
                  estate, automobiles, furniture, and more.
                </p>
              </div>

              <div className="main_videosi">
                <div className="without">
                  <img
                    src="media/landing/Without-Pixhoto.svg"
                    className="img-fluid"
                  />
                </div>
                <div className="with">
                  <img
                    src="media/landing/With-Pixhoto.svg"
                    className="img-fluid"
                  />
                </div>
                <div id="sync1" className="owl-carousel owl-theme">
                  <Carousel
                    // images={retouchingSampleImgs}
                    style={{
                      width: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "none",
                    }}
                    canAutoPlay={false}
                    hasSizeButton={false}
                    hasIndexBoard={false}
                    hasLeftButton={false}
                    hasRightButton={false}
                    hasThumbnails={false}
                  >
                    <div className="text-slide item">
                      <div className="timg">
                        <img
                          src="media/landing/Banner-for-GIF-New-Woman.gif"
                          className="img-fluid"
                          alt="primg"
                        />
                      </div>
                    </div>
                    <div className="text-slide item">
                      <div className="timg">
                        <img
                          src="media/landing/Banner-for-GIF-New-Woman.gif"
                          className="img-fluid"
                          alt="primg"
                        />
                      </div>
                    </div>
                    <div className="text-slide item">
                      <div className="timg">
                        <img
                          src="media/landing/Banner-for-GIF-New-Woman.gif"
                          className="img-fluid"
                          alt="primg"
                        />
                      </div>
                    </div>
                    <div className="text-slide item">
                      <div className="timg">
                        <img
                          src="media/landing/Banner-for-GIF-New-Woman.gif"
                          className="img-fluid"
                          alt="primg"
                        />
                      </div>
                    </div>
                  </Carousel>
                </div>


              </div>
              <div className="butonn_tenphoto">
                <Link to="/auth/registration">
                  Get First 10 Photos For Free
                </Link>
                <h4>Limited period offer. Guaranteed high quality output.</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-retouching_section--> */}

      {/* <!--retouching_services--> */}
      <section id="retouching_services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head graycolor">
                <h1>Pixhoto’s Retouching Services</h1>
                <p>
                  High quality retouching at most affordable prices. Round the
                  clock operation, covering all time zones.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="be_afbox">
                <img
                  src="media/landing/AdobeStock_281849447 cop 2 - Retouching.gif"
                  className="img-fluid"
                  alt="retimg"
                />
                <p>Retouching</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="be_afbox">
                <img
                  className="img-fluid"
                  src="media/landing/323261515_gif-ImageEditing.gif"
                  alt="retimg"
                />
                <p>Photo Editing</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="four_box">
                {/* <img src="media/landing/imagbg.png" className="img-fluid" /> */}
                <video src="media/landing/Background Removal.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Background Removal</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/Clipping Path.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Clipping Paths</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/Colourways.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Color Match</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/Invisible Mannequin.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Invisible Mannequin</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/Shadow and Reflection.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Shadows</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/ColourFix.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>ColourFix</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/Retouching.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Retouching</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="four_box">
                <video src="media/landing/Creative Composition.mp4" loop={true} className="img-fluid" autoPlay={true} muted={true} controls={false}></video>
                <p>Creative Composition</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="addmore">
                <p>And more...</p>
                <ul>
                  <li><span>Accessories Retouching</span></li>
                  <li><span>Furniture Retouching</span></li>
                  <li><span>Jewellery Retouching</span></li>
                  <li><span>Watches Retouching</span></li>
                  <li><span>Cosmetics Retouching</span></li>
                  <li><span>Attention To Detail</span></li>
                  <li><span>High Quality Output</span></li>
                  <li><span>Fast Turnaround Time</span></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row" id="scrollPos">
            <div className="col-lg-12">
              <div className="butonn_tenphoto gray">
                <Link to="/auth/registration">

                  Get First 10 Photos For Free
                </Link>
                <h4>Limited period offer. Guaranteed high quality output.</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-retouching_services--> */}

      {/* <!--achievements_section--> */}
      <section id="achievements_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head">
                <h1>Pixhoto’s Proud Achievements</h1>
                <p>
                  High power team, with fast & friendly global customer support.
                  Round the clock operation, covering all time zones
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="achie_box">
                <h3>
                  {/* <i className="fa fa-magic" aria-hidden="true"></i> */}
                  <img src="media/landing/magic_icon.svg" />
                </h3>
                <h1><span class="timer count-title count-number" data-to="80000" data-speed="3000">80,000</span></h1>
                <p>hours of experience creating magical images</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="achie_box">
                <h3>
                  {" "}
                  <img src="media/landing/award_cup.svg" />

                </h3>
                <h1><span class="timer count-title count-number" data-to="1500000" data-speed="3000">1,500,000</span></h1>
                <p>images retouched so far</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="achie_box">
                <h3>
                  <img src="media/landing/Star.svg" />


                </h3>
                <h1>5-Star</h1>
                <p>ratings by Pixhoto customers</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="achie_box">
                <h3>
                  <img src="media/landing/thumbs_up.svg" />

                </h3>
                <h1><span class="timer count-title count-number" data-to="500" data-speed="4000">500</span>+</h1>
                <p>niches & product types worked on</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="achie_box">
                <h3>
                  <img src="media/landing/clock_hour.svg" />

                </h3>
                <h1><span class="timer count-title count-number" data-to="99.97" data-speed="4000">99.97%</span></h1>
                <p>images delivered on time</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-achievements_section--> */}

      {/* <!--reviews_section--> */}
      <section id="reviews_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head graycolor">
                <h1>Thousands Of 5-Star Customer Reviews</h1>
                <p>
                  Read what customers are saying about Pixhoto. We offer you
                  high quality retouching at affordable prices.
                </p>
              </div>

              <div id="owl-demo" className="owl-carousel owl-theme">
                <Carousel
                  style={{
                    width: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "none",
                  }}
                  canAutoPlay={false}
                  hasSizeButton={false}
                  hasIndexBoard={false}
                  hasLeftButton={false}
                  hasRightButton={false}
                  hasDotButtons="bottom"
                  hasThumbnails={false}
                >
                  <div className="item">
                    <div className="slider_box">
                      <div className="clint_rew">
                        <img
                          src="media/landing/dublcot.svg"
                          className="img-fluid firstd"
                          alt="duble"
                        />
                        <p>
                          I wholeheartedly recommend any commercial studio or brand owner who is looking for a long-term post-production partner to work with Pixhoto. Quality, Consistency and Service are all top-notch.
                        </p>
                        <img
                          src="media/landing/dublcot1.svg"
                          className="img-fluid firstd1"
                          alt="duble"
                        />
                      </div>
                      <h5>
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                      </h5>
                      <h3>
                        David Danon, eCommerce business owner{" "}

                      </h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_box">
                      <div className="clint_rew">
                        <img
                          src="media/landing/dublcot.svg"
                          className="img-fluid firstd"
                          alt="duble"
                        />
                        <p>
                          The combination of quality and consistency of work along with the high-touch customer service we receive from Pixhoto is head and shoulders above others that we have worked with in the past.
                        </p>
                        <img
                          src="media/landing/dublcot1.svg"
                          className="img-fluid firstd1"
                          alt="duble"
                        />
                      </div>
                      <h5>
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                      </h5>
                      <h3>
                        Rob Cohen, Owner, Product Photography Studio
                      </h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_box">
                      <div className="clint_rew">
                        <img
                          src="media/landing/dublcot.svg"
                          className="img-fluid firstd"
                          alt="duble"
                        />
                        <p>
                          Getting up and running with Pixhoto is super easy – from the ability to quickly set my editing requirements in the form of specifications, to the convenient transfer of files, and options to get images edited in bulk etc. The reason why I get all our images edited using Pixhoto!
                        </p>
                        <img
                          src="media/landing/dublcot1.svg"
                          className="img-fluid firstd1"
                          alt="duble"
                        />
                      </div>
                      <h5>
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                      </h5>
                      <h3>
                        Adrian, Photographer
                      </h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_box">
                      <div className="clint_rew">
                        <img
                          src="media/landing/dublcot.svg"
                          className="img-fluid firstd"
                          alt="duble"
                        />
                        <p>
                          We've worked with several post-production teams, and the combination of quality and consistency of work along with the high-touch customer service we receive from Pixhoto is head and shoulders above others that we have worked with in the past.
                        </p>
                        <img
                          src="media/landing/dublcot1.svg"
                          className="img-fluid firstd1"
                          alt="duble"
                        />
                      </div>
                      <h5>
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                        <img src="media/misc/rew.svg" className="img-fluid" />
                      </h5>
                      <h3>
                        Tim Saner, Shopify Seller
                      </h3>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-reviews_section--> */}

      {/* <!--retouching_requirements--> */}
      <section id="retouching_requirements">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head">
                <h1>
                  Send us Your Retouching Requirements{" "}
                  <em>
                    <u>In Less Than 60 Seconds</u>
                  </em>
                </h1>
                <p>
                  Pixhoto has an automated briefing process to save you time,
                  reusable 1-click marketplace templates, instant quote, and
                  more.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="pcimg">
                <img
                  src="media/landing/pcimg.png"
                  className="img-fluid"
                  alt="pc"
                />
                <img
                  src="media/landing/logos.png"
                  className="img-fluid"
                  alt="logos"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="list_box">
                <ul>
                  <li>1-click Google / Facebook signup.</li>
                  <li>Easy upload & specification process.</li>
                  <li>Get instant quote. No need to wait.</li>
                  <li>5-Star ratings by Pixhoto customers.</li>
                  <li>Customized plans for large volumes.</li>
                  <li>
                    Spend less time with us. More with your business. Try
                    Pixhoto today!
                  </li>
                  <li>
                    1-click templates for Amazon, Google Shopping, eBay,
                    Facebook Marketplace, Walmart, Shopify. and more.
                  </li>
                </ul>
                <div className="butonn_tenphoto medbtn">
                  <Link to="/auth/registration">

                    Get First 10 Photos For Free
                  </Link>
                  <h4>Limited period offer. Guaranteed high quality output.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-retouching_requirements--> */}

      {/* <!--faq_section--> */}
      <section id="faq_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head graycolor">
                <h1>Frequently Asked Questions</h1>
                <p>
                  Read what customers are saying about Pixhoto. We offer you
                  high quality retouching at affordable prices.
                </p>
              </div>
              <div className="acodinfaqstin">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {" "}
                          How does Pixhoto work?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            Pixhoto is very easy to use. Simply, signup/create
                            an account. Once you’re logged in, select the
                            eCommerce store of your choice from the marketplace
                            templates. You can also use our guided setup or
                            manually fill out the specifications to let us know
                            the output you require. After you have set up the
                            specifications, place your order by uploading images
                            via the cloud, FTP or your computer. Hit the submit
                            button and we will get to work on your images. As
                            soon as your images are ready, we’ll let you know.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">

                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {" "}
                          Do the 10 free images need a credit card?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            Credit card is not required for Signing-up or for free trial images on Pixhoto
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {" "}
                          How many images can I upload for editing?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            You may upload up to 250 images in one batch. However, there is no limit to the number of batches that may be uploaded in a day.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          {" "}
                          Can I customize my editing requirements?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            Yes, you can. Our image editing platform gives you the ability to choose from standardized specifications by store type from Marketplace (like Amazon, Google Shopping, eBay, Etsy, ASOS, etc.) as per the intended usage of images. Or you may create custom specifications in a matter of clicks yourself using our guided specification settings through our Step- By Step module and have them saved for current or future use.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          {" "}
                          Can I share my feedback on the edited images?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            Yes. PIXHOTO accepts up to two rounds of changes requested by the customer provided the changes requested are genuine or a mistake/error on the part of PIXHOTO.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" id="headingsix">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapsesix"
                          aria-expanded="false"
                          aria-controls="collapsesix"
                        >
                          {" "}
                          What file types do you work with?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapsesix"
                      className="collapse"
                      aria-labelledby="headingsix"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            We use all popular file formats, you may upload JPG, PNG, TIFF, or PSD product images for editing
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" id="headingeight">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseeight"
                          aria-expanded="false"
                          aria-controls="collapseeight"
                        >
                          {" "}
                          Can you standardize my images for eBay, Amazon or any
                          other marketplace?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseeight"
                      className="collapse"
                      aria-labelledby="headingeight"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="faq_boxs">
                          <p>
                            Most definitely. You can select the template for all major eCommerce stores and standardize your images as per the store specifications.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-faq_section--> */}

      {/* <!--form_section--> */}
      <section id="form_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact_text">
                <h3>Need large volumes retouched on daily basis?</h3>
                <h1>Contact Us For Large Volumes.</h1>
                <h5>
                  Do you need a large volume of images reotouched on a regular
                  basis? We will be happy to work with you and give you a custom
                  plan which will best suit your needs.
                </h5>
                <ul>
                  <li>80,000+ hours of experience creating magical images.</li>
                  <li>More than 1,500,000 images edited by Pixhoto.</li>
                  <li>99.97% images delivered on time.</li>
                  <li>5-Star ratings by Pixhoto customers</li>
                  <li>Round the clock operation, covering all time zones.</li>
                  <li>Customized plans for brands with large volumes.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_box">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label>Your Name*</label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses("name")}`}
                      placeholder="Enter your name"
                      {...formik.getFieldProps("name")}
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label>Your Email*</label>
                    <input
                      type="email"
                      className={`form-control ${getInputClasses("email")}`}
                      placeholder="Enter your email"
                      {...formik.getFieldProps("email")}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label>Image Capacity Needed*</label>
                    <select className={`form-control ${getInputClasses("imagesQuantity")}`}
                      {...formik.getFieldProps("imagesQuantity")}>
                      <option>Select quantity</option>
                      <option value="<100 per month">&lt;100 per month</option>
                      <option value="100 - 500 per month">100 - 500 per month</option>
                      <option value="500 - 1000 per month">500 - 1000 per month</option>
                      <option value="1000 + per month">1000 + per month</option>
                    </select>
                  </div>
                  {formik.touched.imagesQuantity && formik.errors.imagesQuantity ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.imagesQuantity}</div>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label>Any Comments</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Share important details here"
                      {...formik.getFieldProps("comments")}
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn">
                      Submit Form
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-form_section--> */}

      {/* <!--pricing_image--> */}
      <section id="pricing_image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pricingimg">
                <div className="withpix">
                  <img
                    src="media/landing/withpixhoto.svg"
                    className="img-fluid"
                    alt="with"
                  />
                </div>
                <img
                  src="media/landing/pricing-page-bottom.png"
                  className="img-fluid"
                />
              </div>
              <div className="butonn_tenphoto gray btn_bot">
                <Link to="/auth/registration">

                  <img
                    src="media/landing/gift.png"
                    className="img-fluid"
                    alt="gift"
                  />{" "}
                  Get First 10 Photos For Free
                </Link>
                <h4>Limited period offer. Guaranteed high quality output.</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-pricing_image--> */}

      {/* <!--footer--> */}
      <section id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="logo">
                <a href="#">
                  <img src="media/landing/flogo.svg" className="img-fluid" />
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-8">
              <p>© Pixhoto 2022. All rights reserved.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End-footer--> */}
    </>
  )
}
