import React from "react"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import Videoembed from "../../_metronic/_partials/dashboards/Videoembed"
import "./viewAllVideos.css"
export const ViewAllVideos = () => {

    return (
        <Card
        className={`card-custom gutter-b`}
        style={{ padding: "2rem", height: "100%" }}>
        <div className="card-toolbar">
        <Link to="/dashboard">
          <button className="btn btn-light-primary font-weight-bolder font-size-sm mr-3 float-left">
            Back
          </button>
        </Link>
      </div>
            <Card.Header>
            <h2 className="card-title align-items-start flex-column">
          <span
            className="card-label font-weight-bolder text-dark"
            style={{ fontSize: "2.2rem" }}
          >
           All Videos
          </span>
          
        </h2>
            </Card.Header>
            <Card.Body>
                <div className="grid-container">
                    <div className="grid-item">
                        <Videoembed embedId="3Iw-R5adUPM" key={1} />
                        <p>1. Introduction</p>
                    </div>
                    <div className="grid-item">
                        <Videoembed embedId="l2FYjKwCgK8" key={2} />
                        <p>2. Step By Step</p>
                    </div>
                    <div className="grid-item">
                        <Videoembed embedId="7PM7xP85m_8" key={3} />
                        <p>3. Marketplace</p>
                    </div>
                    <div className="grid-item">
                        <Videoembed embedId="omDIRfJ96fI" key={4} />
                        <p>4. Place Order</p>
                    </div>
                    <div className="grid-item">
                        <Videoembed embedId="XZe3yzxyaRg" key={5} />
                        <p>5. Colour Fix</p>
                    </div>
                    <div className="grid-item">
                        <Videoembed embedId="2tmVRXbWmFo" key={6} />

                        <p>6. Markup Tool</p>
                    </div>
                    <div className="grid-item">
                        <Videoembed embedId="fTgWqyHqH1A" key={7} />

                        <p>7. Invisible <br/> Mannequin</p>
                    </div>

                </div>
            </Card.Body>
        </Card>
    )
}