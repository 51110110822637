import React, { useMemo, useEffect } from "react"
import objectPath from "object-path"
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout"
// Import Layout components
import { Header } from "./header/Header"
import { HeaderMobile } from "./header-mobile/HeaderMobile"
import { Aside } from "./aside/Aside"
import { Footer } from "./footer/Footer"
import { LayoutInit } from "./LayoutInit"
import { useLocation } from "react-router-dom"
import "../../_assets/sass/components/_variables.bootstrap.scss"

export function Layout({ children }) {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const pathname = location.pathname
  // Layout settings (cssClasses/cssAttributes)

  //In case of marketplace, set container height to auto
  const splClass =
    pathname.includes("/specifications/edittemplate") ||
    pathname.includes("/image-library") ||
    pathname.includes("/specifications/imagesize")
      ? "autoHeight"
      : ""

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop",
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    }
  }, [uiService])

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {layoutProps.subheaderDisplay}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div
                  className="d-flex flex-column-fluid"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/*begin::Container*/}
                  <div
                    className={`${layoutProps.contentContainerClasses} ${splClass}`}
                    style={{
                      alignItems: "center",
                      height: "auto",
                      minHeight: "70vh",
                    }}
                  >
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/* <QuickUser /> */}
      {/* <QuickPanel /> */}
      {/* <ScrollTop/>
            <StickyToolbar/> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  )
}
