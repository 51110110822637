export default function data() {
  return {
    templateId: 0,
    name: "",
    turnAroundTime: "24",
    category: "",
    brand: "",
    createdDate: 0,
    currency: "ca",
    settingUIData: {
      fileType: "JPG",
      backgroundType: "White",
      backgroundHex: "#FFFFFF",
      backgroundRStyle: "255",
      backgroundGStyle: "255",
      backgroundBStyle: "255",
      backgroundAStyle: "1",
      size: "Aspect Ratio",
      minSizeInput: 500,
      maxSizeInput: 700,
      widthInRatio: 5,
      heightInRatio: 7,
      minSizeInputSquare: 500,
      maxSizeInputSquare: 500,
      widthInRatioSquare: 5,
      heightInRatioSquare: 5,
      sizeSetting: "original",
      sizeUnit: "px",
      minmax: "None",
      isMargin: true,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      marginUnit: "%",
      alignment: "Center",
      colorProfile: "sRGB",
      compression: "None",
      autoRotate: "Yes",
      aspectratio: "1:1(Square)",
      metadata: "Keep all",
      dpi: "None",
      dpiValue: 0,
      isdpiSpecify: false,
      isdpiNoChange: false,
      prefix: "",
      postfix: "",
      maxFileSizeType: "None",
      maxFileSize: 0,
      progressive: true,
      normalizedRotation: true,
      category: "",
    },
    totalPrice: 1.2,
    price: 1.2,
    noteToSelf: "",
    tags: "",
    isModel: false,
    isManneqin: false,
    isFlats: false,
    output: { hasAdditionalOutput: true, additionalOutput: [] },
    cropping: {
      fullBody: { isFullBody: true, cropofHead: "None", fullBodyPic: "nonefb" },
      top: {
        isTop: false,
        cropofHead: "None",
        topHead: "None",
        cropofLegs: "Mid Thigh",
        topLegs: "midthigh",
      },
      bottom: {
        isBottom: false,
        cropofBody: "At the waist",
        bottomPic: "atthewaist",
      },
    },
    addons: {
      shadow: "None",
      shadowPrice: 0,
      shadowLevel: 0,
      showInvisibleMannequin: true,
      invisibleMannequin: "None",
      invisibleMannequinPrice: 0,
      invisibleMannequinLevel: 0,
      showClippingPath: true,
      clippingPath: "None",
      clippingPathPrice: 0,
      clippingPathLevel: 0,
      colorMatching: "None",
      colorMatchingPrice: 0,
      colorMatchingLevel: 0,
    },
    retouching: {
      basic: "None",
      basicPrice: 0,
      basicLevel: 0,
      isRemoveLabel: false,
      garment: "None",
      garmentPrice: 0,
      modelRetouch: "None",
      modelRetouchPrice: 0,
      modelRetouchLevel: 0,
      modelGarment: "None",
      modelGarmentPrice: 0,
      modelGarmentLevel: 0,
      modelBackground: "None",
      modelBackgroundPrice: 0,
      modelBackgroundLevel: 0,
      retouchCategory: "modelRetouch",
      retouch: "None",
      retouchPrice: 0,
      retouchLevel: 0,
      retouchGuidedSpec: false,
    },
    additionalOutput: {
      fileType: "JPG",
      backgroundType: "White",
      backgroundHex: "#000000",
      backgroundRStyle: "0",
      backgroundGStyle: "0",
      backgroundBStyle: "0",
      backgroundAStyle: "1",
      size: "Trim(Crop tightly)",
      minSizeInput: 0,
      maxSizeInput: 0,
      sizeUnit: "px",
      minmax: "None",
      isMargin: true,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      marginUnit: "%",
      alignment: "Top",
      colorProfile: "sRGB",
      compression: "None",
      autoRotate: true,
      aspectratio: "1:1(Square)",
      metadata: "Keep all",
      dpi: "None",
      dpiValue: 0,
      isdpiSpecify: false,
      isdpiNoChange: false,
      prefix: "",
      postfix: "",
      maxFileSizeType: "None",
      maxFileSize: 0,
      progressive: true,
      normalizedRotation: true,
    },
    additionalOutputList: [],
  }
}
