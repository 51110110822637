import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { connect, useDispatch } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { login, register } from "../_redux/authCrud"
import firebase from "../../../firebase/firebase"
import GoogleButton from "react-google-button"
import { Modal, Button, Card } from "react-bootstrap"
import queryString from "query-string"
import userDetails from "../__mocks__/userDetails"
import { userContext } from "../../../../_metronic/layout/_core/MetronicUserLoginProvider"
import "./styles/login.css"

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
}

function Login(props) {
  const searchParams = new URLSearchParams(props.location.search)
  const plan = searchParams.get("plan")

  const { setFirstTimeUserLogin } = useContext(userContext)

  const dispatch = useDispatch()
  let url = props.location.search
  let params = url

  //loginUserThroughEmailVerificationLink(params);
  const { intl } = props
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [errorCred, seterrorCred] = useState()
  const [emailVerificationError, setEmailVerificationError] = useState(false)
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    password: Yup.string()
      .min(6, "Minimum 6 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
  })

  async function loginUserThroughEmailVerificationLink(params) {
    //Will execute if the login page is accessed through
    //email verification link

    if (params && params.email != null && params.access != null) {
      //Check if the email is verified
      //If User is null then it means Firebase auth has not happned
      // if (user != null && user.emailVerified) {
      //   await firebase.db
      //     .collection(firebase.USER_COLLECTION)
      //     .doc(user.uid)
      //     .get()
      //     .then((response) => {
      //       userDetails.splice(0, userDetails.length);
      //       const userDetail = response.data();
      //       if (
      //         userDetail.email == params.email &&
      //         userDetail.accessToken == params.access
      //       ) {
      //         userDetails.push(userDetail);
      //         login(userDetail.email, userDetail.password).then(
      //           ({ data: { accessToken } }) => {
      //             props.login(accessToken);
      //           }
      //         );
      //       }
      //       setUserLocation(userDetail);
      //     });
      // } else if (user == null) {
      //Retrieve User Details from DB based on the url param values
      var dbUserDetail
      userDetails.splice(0, userDetails.length)
      await firebase.db
        .collection(firebase.USER_COLLECTION)
        .where("email", "==", params.email)
        .where("accessToken", "==", params.access)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            dbUserDetail = doc.data()
            userDetails.push(doc.data())
            //Authenticate with Firestore
            await firebase
              .login(dbUserDetail.email, dbUserDetail.password)
              .then(response => {
                if (response.user.emailVerified) {
                  //login the user

                  setFirstTimeUserLogin(true) //user Logs in through email verification for the first time

                  login(dbUserDetail.email, dbUserDetail.password).then(
                    ({ data: { accessToken } }) => {
                      props.login(accessToken)
                    },
                  )
                  handleUpdateUserLoggedIn(response)
                  setUserLocation(dbUserDetail)
                }
              })
          })
        })
    }
  }
  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      try {
        authenticateUser(setStatus, setSubmitting, values)
      } catch (err) {
        setStatus("Oops something went wrong! Please try refreshing the page")
      }
    },
  })

  async function authenticateUser(setStatus, setSubmitting, values) {
    userDetails.splice(0, userDetails.length)
    //User Auth with Firebase
    await firebase
      .login(values.email, values.password)
      .then(response => {
        if (response.user.emailVerified) {
          handlePasswordReset(response, values)
          setTimeout(() => {
            //push the User Details to local storage for accessablity
            firebase.db
              .collection(firebase.USER_COLLECTION)
              .doc(response.user.uid)
              .get()
              .then(user => {
                userDetails.push(user.data())
                disableLoading()
                //Log in  the User
                //if first time user, set context before login
                if (user.data().firstTimerUser) {
                  setFirstTimeUserLogin(true)
                } else {
                  setFirstTimeUserLogin(false)
                }
                login(values.email, values.password).then(
                  ({ data: { accessToken } }) => {
                    props.login(accessToken)
                  },
                )
                handleUpdateUserLoggedIn(response, false)
                setUserLocation(user.data());
              })
          }, 1000)
        } else {
          disableLoading()
          setSubmitting(false)
          setEmailVerificationError(true)
          setStatus(
            "The entered email id is not verified. Click here to resend the verification link",
          )
        }
      })
      .catch(e => {
        disableLoading()
        setSubmitting(false)
        if (e.code === "auth/user-disabled") {
          setStatus(
            "This account is disabled, Please contact the admin for assistance.",
          )
        } else {
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            }),
          )
        }
      })
  }

  //After user logs in for the first time, update the backend
  async function handleUpdateUserLoggedIn(response, firstTimerUser = true) {
    await firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .update({
        firstTimerUser: firstTimerUser,
      })
  }

  async function handlePasswordReset(response, values) {
    await firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .get()
      .then(user => {
        if (values.password !== user.data().password) {
          firebase.db
            .collection(firebase.USER_COLLECTION)
            .doc(response.user.uid)
            .update({
              password: values.password,
            })
        }
      })
  }

  async function signInWithSocialMedia(provider) {
    userDetails.splice(0, userDetails.length)
    try {
      await firebase.socialLogin(provider).then(response => {
        loginOrRegisterSocialUser(response, provider)
      })
    } catch (err) {
      if (err.code === "auth/account-exists-with-different-credential") {
        seterrorCred(err)
        setModalShow(true)
      } else {
        formik.setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          }),
        )
      }
    }
  }

  async function linkCredentials() {
    await firebase.linkCredentials(errorCred).then(response => {
      loginOrRegisterSocialUser(response, "")
      setModalShow(false)
    })
  }

  async function loginOrRegisterSocialUser(response, provider) {
    await firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .get()
      .then(user => {
        handleUpdateUserLoggedIn(response, false)
        setUserLocation(user.data())

        if (user.exists) {
          console.log("userDAta: ", user.data())
          userDetails.push(user.data())
          login(user.data().email, user.data().password).then(
            ({ data: { accessToken } }) => {
              disableLoading()
              props.login(accessToken)
              setFirstTimeUserLogin(false)
            },
          )
        } else {
          const givenName =
            provider === "google"
              ? response.additionalUserInfo.profile.given_name
              : response.additionalUserInfo.profile.name
          register(
            response.additionalUserInfo.profile.email,
            response.additionalUserInfo.profile.name,
            givenName,
            response.additionalUserInfo.profile.id,
          )
            .then(({ data: { accessToken } }) => {
              props.register(accessToken)

              disableLoading()
              setFirstTimeUserLogin(true) // user logs in first time throguh gmail login
              postAuth(accessToken, response, provider)
            })
            .catch(e => {
              console.log(e)
              disableLoading()
            })
        }
      })
      .catch(() => {
        formik.setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          }),
        )
      })
  }

  const resendEmailVerification = () => {
    if (firebase.auth.currentUser) {
      firebase.auth.currentUser
        .sendEmailVerification()
        .then(() => {
          setEmailVerificationError(false)
          formik.setStatus(
            "Verification link is sent to your registered email address",
          )
        })
        .catch(error => {
          formik.setStatus(error.message)
        })
    }
  }

  // function getGeoInfo() {
  //   return new Promise((resolve, reject) => {
  //     fetch("https://ipapi.co/json/")
  //       .then(res => res.json())
  //       .then(response => {
  //         resolve({
  //           countryName: response.country_name,
  //           countryCode: response.country_calling_code,
  //           city: response.city
  //         });
  //       })
  //       .catch(error => {
  //         console.log(error);
  //         reject(error)
  //       });
  //   })

  // }






  function getGeoInfo() {
    return new Promise((resolve, reject) => {
      fetch("https://ipapi.co/json/")
        .then(res => res.json())
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error)
        });
    })

  }




  // const setUserLocation = user => {
  //   //Set currency to CAD only when the user allowes to give location
  //   //else the default currency is USD
  //   console.log("setting location")
  //   //get the lat and lan
  //   navigator.geolocation.getCurrentPosition(
  //     function(position) {
  //       //get the address using Google Geocoding API
  //       fetch(
  //         "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
  //           position.coords.latitude +
  //           "," +
  //           position.coords.longitude +
  //           "&key=" +
  //           firebase.GOOGLE_API_KEY,
  //       )
  //         .then(res => res.json())
  //         .then(response => {
  //           //Get only the country name from the response list
  //           const country = response.results[0].address_components.find(
  //             address => address.types[0] === "country",
  //           )
  //           const location = response.results[0].address_components.find(
  //             address => address.types[0] === "locality",
  //           )
  //           console.log({country})
  //           user.currency =country.long_name.toLowerCase() === "canada" ? "ca" : "us"
  //           //update local user data with currency
  //           console.log("fomatterd addtrss", location.long_name)
  //           user.address.city = location.long_name
  //           console.log({ userDetails })
  //           userDetails.splice(0, userDetails.length)
  //           userDetails.push(user)
  //           dispatch(props.setUser(user))
  //           //update in backend with user currency
  //           firebase.db
  //             .collection(firebase.USER_COLLECTION)
  //             .doc(user.id)
  //             .set(user)
  //             .catch(e => {
  //               console.log(e)
  //             })
  //         })
  //         .catch(error => {
  //           console.log(error)
  //         })
  //     },
  //    async function(error) {
  //       console.error("location permission error");
  //       const { countryName, countryCode, city } = await getGeoInfo();
  //       user.currency = countryName.toLowerCase() === "canada" ? "ca" : "us";
  //       user.address.city = city;
  //       console.log({ userDetails });
  //       userDetails.splice(0, userDetails.length);
  //       userDetails.push(user);
  //       dispatch(props.setUser(user));
  //       //update in backend with user currency
  //       firebase.db
  //         .collection(firebase.USER_COLLECTION)
  //         .doc(user.id)
  //         .set(user)
  //         .catch(e => {
  //           console.log(e);
  //         });
  //       console.log(error);
  //     },
  //   )
  // }












  const setUserLocation = async (user) => {
    try {
      //Set currency to CAD only when the user allowes to give location
      //else the default currency is USD
      console.log("setting location")
      //get the lat and lan
      console.log(user, 'in user')

      let response = await getGeoInfo()
      console.log("🚀 ~ file: Login.js:475 ~ setUserLocation ~ response:", response)
      user.currency = response.country_name.toLowerCase() === "canada" ? "ca" : "us";
      user.address.city = response.city;
      console.log({ userDetails });
      userDetails.splice(0, userDetails.length);
      userDetails.push(user);
      dispatch(props.setUser(user));
      //update in backend with user currency
      firebase.db
        .collection(firebase.USER_COLLECTION)
        .doc(user.id)
        .set(user)
        .catch(e => {
          console.log(e);
        });
    } catch (err) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          //get the address using Google Geocoding API
          fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "&key=" +
            firebase.GOOGLE_API_KEY,
          )
            .then(res => res.json())
            .then(response => {
              //Get only the country name from the response list
              const country = response.results[0].address_components.find(
                address => address.types[0] === "country",
              )
              const location = response.results[0].address_components.find(
                address => address.types[0] === "locality",
              )
              console.log({ country })
              user.currency = country.long_name.toLowerCase() === "canada" ? "ca" : "us"
              //update local user data with currency
              console.log("fomatterd addtrss", location.long_name)
              user.address.city = location.long_name
              console.log({ userDetails })
              userDetails.splice(0, userDetails.length)
              userDetails.push(user)
              dispatch(props.setUser(user))
              //update in backend with user currency
              firebase.db
                .collection(firebase.USER_COLLECTION)
                .doc(user.id)
                .set(user)
                .catch(e => {
                  console.log(e)
                })
            })
            .catch(error => {
              console.log(error)
            })
        })
    }
  }





  const postAuth = (accessToken, response, provider) => {
    const user = userDetails.find(x => x.accessToken === accessToken)
    if (plan === "" || plan === null) user.plan = "pro"
    else user.plan = plan
    user.id = response.user.uid
    user.pic =
      provider === "facebook"
        ? response.user.photoURL + "?height=500"
        : response.user.photoURL
    userDetails.splice(0, userDetails.length)
    userDetails.push(user)
    setUserLocation(user)
    firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .set(user)
      .catch(e => {
        console.log(e)
      })
    firebase.createStripeUser(user.email, user.fullname, response.user.uid)
    sendWelcomeEmail(user.email, user.fullname)
  }

  const sendWelcomeEmail = (email, fullname) => {
    const sendWelcomeEmail = firebase.functions.httpsCallable(
      "sendWelcomeEmail",
    )
    sendWelcomeEmail({
      email: email,
      userName: fullname,
    })
  }

  function password_show_hide() {
    var x = document.getElementById("password")
    var show_eye = document.getElementById("show_eye")
    var hide_eye = document.getElementById("hide_eye")
    show_eye.classList.remove("d-none")
    if (x.type === "password") {
      x.type = "text"
      show_eye.style.display = "block"
      hide_eye.style.display = "none"
    } else {
      x.type = "password"
      show_eye.style.display = "none"
      hide_eye.style.display = "block"
    }
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <ul className="socilgi dlidersi">
        <li>
          <GoogleButton
            style={{ width: "70", float: "none", position: "static" }}
            onClick={() => signInWithSocialMedia("google")}
          />
        </li>
        <li>
          <div
            onClick={() => signInWithSocialMedia("facebook")}
            style={{ display: "block" }}
          >
            <a className="btn btn-block btn-social btn-facebook">
              <i className="fab fa-facebook fa-fw fa-pull-left"></i> Sign in
              with Facebook
            </a>
          </div>
        </li>
      </ul>
      <p className="orword">Or</p>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          emailVerificationError ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                The entered email id is not verified.
                <a onClick={resendEmailVerification}>
                  {" "}
                  <u>Click here</u>{" "}
                </a>
                to resend the verification link
              </div>
            </div>
          ) : (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )
        ) : null}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Warning!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              An account already exists with the same email address but
              different sign-in credentials. You can link the existing account
              with facebook by clicking "Link accounts" or click "Cancel" and
              login using Google.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => linkCredentials()}>
              Link Accounts
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email",
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="input-group">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password",
              )}`}
              name="password"
              id="password"
              {...formik.getFieldProps("password")}
            />{" "}
            &nbsp;
            <span
              className="input-group-append input-group-text"
              onClick={password_show_hide}
            >
              <i className="fas fa-eye d-none" id="show_eye"></i>
              <i className="fas fa-eye-slash" id="hide_eye"></i>
            </span>
          </div>

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        {/* <p className="g-my-15 g-font-size-13 text-center">Or sign in with:</p> */}
      </form>

      <div></div>

      {/* <FacebookLoginButton
        style={{
          fontFamily: 'sans-serif',
          fontSize: '1.3rem',
          position: 'static',
          paddingLeft: '8rem',
        }}
        onClick={() => signInWithSocialMedia('facebook')}
      /> */}
      <Card
        className="d-flex d-lg-none flex-column-auto flex-column align-items-center mt-5 p-5 "
        style={{ border: "0.5px solid #b0b0b0" }}
      >
        <p>
          The Pixhoto platform is currently not designed for use on mobile
          devices.
        </p>
        <p>Please use the platform on a desktop for an optimal experience.</p>
      </Card>
      {/*end::Form*/}
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Login))
