import React, { useContext, useState } from "react"

const CurrencyContext = React.createContext()

function MetronicCurrencyProvider({ children }) {
  const userCurrency = JSON.parse(localStorage.getItem("userCurrency"))

  const [currentCurrency, setCurrentCurrency] = useState(
    userCurrency ? userCurrency.key : "usd",
  )

  const currencies = [
    {
      currency: "usd",
      conversion: 1,
    },

    { currency: "cad", conversion: 1.21 },
  ]

  function handleChangeCurrency(currency) {
    setCurrentCurrency(currency)
  }

  return (
    <CurrencyContext.Provider
      value={{ currencies, currentCurrency, handleChangeCurrency }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}

export { CurrencyContext, MetronicCurrencyProvider }
