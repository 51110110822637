import React, { Suspense, lazy } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import { useSelector, shallowEqual } from "react-redux"
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout"
import { MyPage } from "./pages/MyPage"
import { DashboardPage } from "./pages/DashboardPage"
import { useEffect } from "react";
import { useLocation } from "react-router";
import { ViewAllVideos } from "./pages/ViewAllVideos"

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage"),
)

const Upload = lazy(() => import("./modules/Order/Upload"))
const ImageLibrary = lazy(() => import("./modules/Tools/ImageLibrary"))
const ColorPicker = lazy(() => import("./modules/Tools/ColorPicker"))
const OrdersPage = lazy(() => import("./modules/Order/OrdersPage"))
const SpecificationsPage = lazy(() =>
  import("./modules/Specifications/SpecificationsPage"),
)

export default function BasePage() {
  const location = useLocation();

  const user = useSelector(state => state.auth.user, shallowEqual)

  const isAdmin = user?.roles[0] === 1000 ? true : false

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */
        isAdmin ? (
          <Redirect exact from="/" to="/orders" />
        ) : (
          <Redirect exact from="/" to="/dashboard" />
        )}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/specifications" component={SpecificationsPage} />
        <Route path="/upload" component={Upload} />
        <Route path="/image-library" component={ImageLibrary} />
        <Route path="/color-picker" component={ColorPicker} />
        <Route path="/view-all-videos" component={ViewAllVideos} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}
