import React, { useState } from "react"

const orderTotalContext = React.createContext()

function MetronicOrderTotalProvider({ children }) {
  const [orderTotal, setOrderTotal] = useState(0)

  return (
    <orderTotalContext.Provider value={{ setOrderTotal, orderTotal }}>
      {children} 
    </orderTotalContext.Provider>
  )
}

export { orderTotalContext, MetronicOrderTotalProvider }
