/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import { Link, Switch, Redirect } from "react-router-dom"
import queryString from "query-string"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ContentRoute } from "../../../../_metronic/layout"
import Login from "./Login"
import Registration from "./Registration"
import ForgotPassword from "./ForgotPassword"
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss"
import { useLocation } from "react-router-dom"
import EnhancedPlan from "./EnhancedPlan"
import BasicPlan from "./BasicPlan"
import PromotionalPage from "./PromotionalPage"
import { Button } from "react-bootstrap"
import "./styles/authPage.css"

export function AuthPage() {
  const location = useLocation()
  const searchLocation = location.search
  const { plan = "" } = queryString.parse(searchLocation)
  // console.log(plan);
  const [subscription, setSubscription] = useState("")
  const [isPro, setIsPro] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [background, setBackground] = useState(
    `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
  )
  const [footerBG, setFooterBG] = useState(
    `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
  )

  useEffect(() => {
    if (plan.length > 0) {
      setSubscription(plan)
      setFooterBG("none")
    } else {
      setIsLogin(true)
      setBackground("none")
    }
  }, [plan])

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="d-none d-lg-block text-center login-aside flex-row-auto bgi-size-cover bgi-no-repeat bg-gray-200"
            style={{
              // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
              backgroundImage: background,
              width: "100rem",
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-column-fluid flex-column ">
              {/* start:: Aside header */}
              <Link to="/" className="mt-40 flex-column-auto">
                <img
                  alt="Logo"
                  className="max-h-100px"
                  src={toAbsoluteUrl("/media/logos/pixhoto-logo.svg")}
                />
                <img
                  alt="Logo"
                  className="max-h-100px loginimge"
                  src={toAbsoluteUrl("/media/general/logingimgs.png")}
                />
              </Link>
              {/* end:: Aside header */}
              {/* start:: Aside content */}
              <div className="flex-row-fluid d-flex flex-column ">
                {plan === "" || plan === null ? (
                  <h3 className="mt-20 font-size-h3 text-dark font-weight-bold">
                    Welcome to Pixhoto!
                  </h3>
                ) : (
                  <>
                    <h3 className="font-size-h3 pt-4 text-white font-weight-bold">
                      Welcome to Pixhoto!
                    </h3>
                    <p className="font-weight-lighter text-white opacity-80">
                      The product image editing service
                    </p>
                  </>
                )}
                <br />
                <div
                  className="align-self-center"
                  style={{
                    width: "30rem",
                    //marginLeft: "8rem"
                    // position: "relative",
                    // left:" 8rem",
                    // right:" 8rem",
                  }}
                >
                  {!isLogin ? (
                    plan === "pro" ? (
                      <EnhancedPlan />
                    ) : (
                      <BasicPlan />
                    )
                  ) : plan === "" || plan === null ? (
                    //  <PromotionalPage />
                    <></>
                  ) : (
                    <></>
                  )}

                  {/* { && }
                  {!isPro && } */}
                  {/* {plan === "" ? "" : <p>this</p>} */}
                </div>
              </div>
              {/* end:: Aside content */}
              {/* start:: Aside footer for desktop */}
              <div>
                <small style={{ color: "rgb(136,130,130)" }}>
                  Terms and conditions apply.
                </small>
              </div>
              <div
                className="navbar fixed-bottom pl-8 flex-column-auto d-lg-flex justify-content-between mt-2 "
                style={{
                  // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
                  backgroundImage: footerBG,
                  height: "5rem",
                }}
              >
                <div className="opacity-70 font-weight-bold	text-white mt-3">
                  &copy; {new Date().getFullYear()} Pixhoto
                </div>
                <div className="d-flex">
                  <a
                    onClick={() =>
                      window.open(
                        "https://pixhoto.com/privacy-policy/",

                        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                      )
                    }
                    target="_parent"
                    href="javascript: void(0)"
                  >
                    <Button variant="link" style={{ color: "white" }}>
                      Privacy
                    </Button>
                  </a>
                  <a
                    onClick={() =>
                      window.open(
                        "https://pixhoto.com/terms-of-service/",

                        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                      )
                    }
                    target="_parent"
                    href="javascript: void(0)"
                  >
                    <Button variant="link" style={{ color: "white" }}>
                      Legal
                    </Button>
                  </a>
                  <a
                    onClick={() =>
                      window.open(
                        "https://pixhoto.com/contact-us/",

                        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                      )
                    }
                    target="_parent"
                    href="javascript: void(0)"
                  >
                    <Button variant="link" style={{ color: "white" }}>
                      Contact
                    </Button>
                  </a>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {location.pathname.includes("/auth/login") && (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">
                  Don't have an account yet?
                </span>
                <Link
                  to={{
                    pathname: "/auth/registration",
                    search: "plan=" + plan,
                  }}
                  className="font-weight-bold ml-2"
                  id="kt_login_signup"
                >
                  {/* <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              > */}
                  Sign Up!
                </Link>
              </div>
            )}

            {location.pathname.includes("/auth/registration") && (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">
                  Already have an account?
                </span>
                <Link
                  to={{
                    pathname: "/auth/login",
                    search: "plan=" + plan,
                  }}
                  className="font-weight-bold ml-2"
                  id="kt_login_signup"
                >
                  {/* <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              > */}
                  Sign In!
                </Link>
              </div>
            )}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 Pixhoto
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a
                  onClick={() =>
                    window.open(
                      "https://pixhoto.com/privacy-policy/",

                      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                    )
                  }
                  target="_parent"
                  href="javascript: void(0)"
                >
                  <Link to="/terms" className="text-dark-75 text-hover-primary">
                    Privacy
                  </Link>
                </a>
                <a
                  onClick={() =>
                    window.open(
                      "https://pixhoto.com/terms-of-service/",

                      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                    )
                  }
                  target="_parent"
                  href="javascript: void(0)"
                >
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Legal
                  </Link>
                </a>
                <a
                  onClick={() =>
                    window.open(
                      "https://pixhoto.com/contact-us/",

                      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                    )
                  }
                  target="_parent"
                  href="javascript: void(0)"
                >
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Contact
                  </Link>
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
