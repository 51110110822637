/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import { useLocation } from "react-router"
import { NavLink, Link } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"

export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : ""
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Specifications Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/specifications", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/specifications">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Compass.svg")} />
            </span>
            <span className="menu-text">Specifications</span>
          </NavLink>
        </li>
        {/*end::Specifications Level*/}
        {/*begin::Orders Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/orders", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Orders</span>
          </NavLink>
        </li>
        {/*end::Orders Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Account</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* User Profile Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg",
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::User Profile Level*/}
        {/* end:: section */}

        {/* Tools */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Tools</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::Color Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/color-picker", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/color-picker">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">Colour Swatches</span>
          </NavLink>
        </li>
        <li className={`menu-item`} aria-haspopup="true">
          <a className="menu-link" href="/dashboard?show_guide=true">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
            <span className="menu-text">Guided Tour</span>
          </a>
        </li>
        {/*end::Color Level*/}

        {/*begin::Image Library Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/image-library", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/image-library">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Image Library</span>
          </NavLink>
        </li> */}
        {/*end::Image Library Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
