import React from "react"

export default function DeleteSVG({ opacity }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="7 7 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill="#F3F6F9" />
      <path
        d="M11.5 13V22C11.5 22.8284 12.1716 23.5 13 23.5H19C19.8284 23.5 20.5 22.8284 20.5 22V13H11.5Z"
        fill="#F64E60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 10.375V10.25C17.5 9.69772 17.0523 9.25 16.5 9.25H15.5C14.9477 9.25 14.5 9.69772 14.5 10.25V10.375H11.25C10.9739 10.375 10.75 10.5989 10.75 10.875V11C10.75 11.2761 10.9739 11.5 11.25 11.5H20.75C21.0261 11.5 21.25 11.2761 21.25 11V10.875C21.25 10.5989 21.0261 10.375 20.75 10.375H17.5Z"
        fill="#F64E60"
      />
    </svg>
  )
}
