import React, { useContext, useEffect, useMemo, useState } from "react"
import objectPath from "object-path"
import SVG from "react-inlinesvg"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../_helpers"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { CurrencySelectorDropdown } from "../extras/dropdowns/CurrencySelectorDropdown"
import { QuickUserToggler } from "../extras/QuiclUserToggler"
import { useLocation } from "react-router-dom"
import { specificationContext } from "../../_core/MetronicSpecificationToEdit"
import { orderTotalContext } from "../../_core/MetronicOrderTotal"
import { useSelector, shallowEqual } from "react-redux"

export function Topbar() {
  const user = useSelector(state => state.auth.user, shallowEqual)
  console.log(user)
  const uiService = useHtmlClassService()

  let location = useLocation()

  const { specificationToEdit } = useContext(specificationContext)

  const [showCurrencySelector, setShowCurrencySelector] = useState(false)

  const { orderTotal } = useContext(orderTotalContext)

  const PATH_NAMES = [
    "/orders/details?",
    "/specifications/list",
    "/dashboard",
    "/orders/list",
    "/user-profile/personal-information",
    "/color-picker",
    "/image-library",
    "/specifications/options",
    "/specifications/marketplaces",
    "/specifications/guided",
  ]

  const PATH_NAMES_SHOW_PRICE = [
    "/specifications/imagesize",
    "/specifications/retouch",
    "/specifications/apparel/cropping",
    "/specifications/background",
    "/specifications/shadow",
    "/specifications/color-matching",
    "/specifications/edittemplate/",
    "/specifications/edittemplate",
    "/specifications/apparel/category",
    "/specifications/ghostmaneequin",
  ]

  // if user is in order details page, currency selector should not be displayed
  const regex = new RegExp("(^.*)??")

  useEffect(() => {
    if (
      !PATH_NAMES.includes(location.pathname) &&
      !PATH_NAMES[0].match(regex)
    ) {
      setShowCurrencySelector(true)
    } else {
      setShowCurrencySelector(false)
    }
  }, [location])

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display",
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display",
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display",
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display",
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display",
      ),
      viewCurrencyDisplay: objectPath.get(
        uiService.config,
        "extras.currency.display",
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    }
  }, [uiService])

  return (
    <div className="topbar">
      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg",
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {showCurrencySelector && (
        <div style={{ display: "flex" }}>
          {" "}
          <span style={{ alignSelf: "center" }}>
            Select Your Currency :
          </span>{" "}
          <CurrencySelectorDropdown />{" "}
        </div>
      )}

      {/* Make price visible on top bar for edit tempalte only */}

      {PATH_NAMES_SHOW_PRICE.includes(location.pathname) && (
        <div className="topbar-item pl-2 pr-2 font-weight-bold border-right">
          {`Total : `} &nbsp;
          <span style={{ fontSize: "1.5em" }}>
            {" "}
            {user.currency.toUpperCase()}${" "}
            {specificationToEdit.totalPrice.toFixed(2)}
          </span>{" "}
          &nbsp; per image
        </div>
      )}

      {location.pathname.includes("/orders/details") && (
        <div className="topbar-item pl-2 pr-2 font-weight-bold border-right">
          {`Total Price: `} &nbsp;
          <span style={{ fontSize: "1.5em" }}>
            {" "}
            {user.currency.toUpperCase()}$ {orderTotal.toFixed(2)}
          </span>{" "}
        </div>
      )}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  )
}
