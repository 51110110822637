import React, { useMemo } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useHtmlClassService } from "../../../_core/MetronicLayout"
import { AsideMenuList } from "./AsideMenuList"
import { AsideMenuListAdmin } from "./AsideMenuListAdmin"

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    }
  }, [uiService])

  const user = useSelector(state => state.auth.user, shallowEqual)
  console.log({ user })
  const isAdmin = user?.roles[0] === 1000 ? true : false

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {isAdmin ? (
          <AsideMenuListAdmin layoutProps={layoutProps} />
        ) : (
          <AsideMenuList layoutProps={layoutProps} />
        )}
      </div>
      {/* end::Menu Container */}
    </>
  )
}
