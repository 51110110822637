import React from "react"
import { Card, CardDeck, Badge, Button, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
export default function ChooseOptions() {
  return (
    <Card
      className={`card-custom gutter-b`}
      style={{ padding: "2rem", height: "100%" }}
    >
      <div className="card-toolbar">
        <Link to="/specifications/list">
          <button className="btn btn-light-primary font-weight-bolder font-size-sm mr-3 float-left">
            Back
          </button>
        </Link>
      </div>
      <Card.Header className={`py-5`}>
        <h2 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark" style={{ fontSize: "2.2rem" }}>
            Set Your Image Specifications
          </span>
          <span className="mt-3 font-size-sm smlhed" style={{ 'opacity': "70%" }}>
            Allows you to define how your images should look


          </span>
        </h2>
      </Card.Header>

      <Card.Body style={{ display: "flex", alignItems: "center", }}>
        <div style={{ width: "100%", gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))", }} className="dt_box">


          <div style={{ height: '0%', display: 'flex', 'justify-content': 'center', background: 'rgb(239 241 251)', width: '100%' }}>

            <div className="row justify-content-around" style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>

              <div className="col-6" >
                <Link to="/specifications/guided">
                  <div className="card  m-auto" style={{ cursor: "pointer" }}>
                    <div style={{ background: 'rgb(239 241 251)' }}>
                      <img src="/media/users/step by step final (1).png" alt="image" className="w-100 rounded" style={{ 'margin-top': '10% ' }} />
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-6">
                <div style={{ 'width': '85%', 'text-align': 'justify', 'margin': '0 auto', 'margin-left': '18px' }}>
                  <h1 className="card-label font-weight-bolder text-dark" style={{ 'display': 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Set Image Specifications</h1>
                  <h5 style={{ 'opacity': '70%', 'text-align': 'center', 'font-size': 'large' }}>Pick and choose from convenient editing options based on your product category</h5>
                  <Link to="/specifications/guided">
                    <Button className="contain btnthemecolor" style={{ 'margin-top': '4%', 'margin-left': '27%' }} size="lg">
                      Get started
                    </Button>
                  </Link>
                </div>

              </div>
            </div>
          </div>


          <div className="mt-4 font-weight-bold font-size-sm" style={{ fontSize: "1.3rem", 'margin-left': '8rem' }}>
            <p style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Or if you need your images edited for popular marketplace platforms like Amazon, Etsy etc. ,</p>
            <h4 style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>Click Below</h4>
            <div style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>
              <Link to="/specifications/marketplaces">
                <Button className="m-2 contain btnthemecolor" size="sm">
                  Marketplaces
                </Button>
              </Link>
            </div>
          </div>

        </div>
      </Card.Body>
    </Card>
  )
}
