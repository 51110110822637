import app from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import { loadStripe } from "@stripe/stripe-js"
import ls from "local-storage"
//dev
// const firebaseConfig = {
//   apiKey: "AIzaSyAZ5CmEKvVeU-3RrLY-uH6_j3CpOPE5kPU",
//   authDomain: "auth.staging.pixhoto.com",
//   projectId: "mm-pixhoto-dev",
//   storageBucket: "mm-pixhoto-dev.appspot.com",
//   messagingSenderId: "863893255358",
//   appId: "1:863893255358:web:b6c19b44f793505ff11aef",
//   measurementId: "G-QS9EL5JX9Y",
// };

//STAGING
// const firebaseConfig = {
//   apiKey: "AIzaSyCqXlFkAsKUNcRLBVVFii57K81qOzXhLL0",
//   authDomain: "auth.staging.pixhoto.com",
//   projectId: "pixhoto-staging",
//   storageBucket: "pixhoto-staging.appspot.com",
//   messagingSenderId: "1083991137141",
//   appId: "1:1083991137141:web:dcc8f7aa4487199293913c",
// };

// LIVE
const firebaseConfig = {
  apiKey: "AIzaSyA5xyK-H_VmY7ZnGPUwIy3zag2txuR6rsw",
  authDomain: "auth.app.pixhoto.com",
  projectId: "pixhoto-prod",
  storageBucket: "pixhoto-prod.appspot.com",
  messagingSenderId: "675401807721",
  appId: "1:675401807721:web:aaad4dd660c941af4f2ff8",
}

class Firebase {
  USER_COLLECTION = "userDetails"
  ORDER_COLLECTION = "orders"
  SPEC_COLLECTION = "specifications"
  COMMON_IMG_LIB = "commonImage"
  USER_IMG_LIB = "usersImage"
  USER_COLORS = "usersColor"
  REDIRECT_URL = "https://app.pixhoto.com/auth/login"
  STRIPE_PUBLIC_KEY =
    "pk_live_51JPSwILGCOjA5uPP3AHVTE3kr6UBtySADo3eYH1YRDWH2nMtETP5ffhT4rtOZqww6lhnJ4IPrJ3VWPqkwDSx7O7L00UPFnR0KP"
  //"pk_test_51JPSwILGCOjA5uPP7qRi5rVvKqJrw7vc7CHIID9V9bDiLzHG0C5rCUcCtf12RS1qYspWlfqIaWCM4lXW2znE5H6000i3lLvN9h";
  GOOGLE_API_KEY = "AIzaSyA5xyK-H_VmY7ZnGPUwIy3zag2txuR6rsw"
  FUNCTION_CALL_COLLECTION = "functionCalls"
  FUNCTION_CALL_DETAIL = "functionCallOrderDetail"

  constructor() {
    this.firbaseApp = app.initializeApp(firebaseConfig)
    this.auth = app.auth()
    this.db = app.firestore()
    this.storage = app.storage()
    this.user = ls.get("persist:v1-pixhoto-auth")
    this.functions = app.functions()
    //this.functions.useFunctionsEmulator('http://localhost:5000')
  }

  /**
   * User
   */

  async getUserID() {
    if (this.auth.currentUser) {
      return this.auth.currentUser.uid
    }
    let user = ls.get("persist:v1-pixhoto-auth")
    return JSON.parse(user?.user)?.id
  }

  async getUserEmailID() {
    if (this.auth.currentUser) {
      return this.auth.currentUser.email
    }
    return JSON.parse(this.user?.user)?.email
  }

  async login(email, password) {
    return await this.auth.signInWithEmailAndPassword(email, password)
  }
  async signInWithGoogle() {
    const googleProvider = new app.auth.GoogleAuthProvider()
    // googleProvider.setCustomParameters({
    //   prompt: "select_account",
    // });
    return await this.auth.signInWithPopup(googleProvider)
  }

  async getUserDetailsById(userId) {
    return await firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .get()
      .then(response => {
        return response.data()
      })
      .catch(e => {
        console.log(e)
        return null
      })
  }

  async getAllUsers() {
    return await firebase.db
      .collection(this.USER_COLLECTION)
  }


  async socialLogin(provider) {
    var socialMediaProvider
    if (provider === "google") {
      socialMediaProvider = new app.auth.GoogleAuthProvider()
      socialMediaProvider.setCustomParameters({
        prompt: "select_account",
      })
    } else if (provider === "facebook") {
      socialMediaProvider = new app.auth.FacebookAuthProvider()
      socialMediaProvider.setCustomParameters({
        display: "popup",
      })
    }
    return await this.auth
      .signInWithPopup(socialMediaProvider)
      .then(response => {
        console.log("firebase:", { response })
        return response
      })
  }

  async linkCredentials(error) {
    var facebookCred = error.credential
    var googleProvider = new app.auth.GoogleAuthProvider()
    // Login to the existing Google account with the same email.
    googleProvider.setCustomParameters({ login_hint: error.email })
    return this.auth.signInWithPopup(googleProvider).then(function (result) {
      // Link Facebook credential to Google account.
      return result.user.linkWithCredential(facebookCred)
    })
  }

  /**
   * End of User
   */

  async retrieveCommonLibrary() {
    return await firebase.db
      .collection(this.COMMON_IMG_LIB)
      .get()
      .then(response => {
        return response.docs.map(doc => doc.data())
      })
      .catch(() => {
        return null
      })
  }

  async retrieveUserLibrary(userId) {
    return await firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_IMG_LIB)
      .get()
      .then(response => {
        return response.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      })
      .catch(() => {
        return null
      })
  }

  async updateImageById(imageId, annotationData) {
    const userId = await this.getUserID()
    return await firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_IMG_LIB)
      .doc(imageId)
      .update({ annotations: annotationData })
  }

  /**
   * Orders
   */

  async retrieveAllOrders() {
    return await firebase.db.collection(this.ORDER_COLLECTION).where("status", "not-in", ["Draft", "Payment Pending", "Approved"])
  }

  async createOrder(orderDetails, orderId, invoiceId) {
    const userId = await this.getUserID()
    orderDetails.userId = userId
    orderDetails.Id = orderId
    orderDetails.invoiceId = invoiceId
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)
    await orderRef.doc(orderId).set(orderDetails)
    const updateOrderDetails = await orderRef
      .doc(orderId)
      .get()
      .then(doc => {
        return doc.data()
      })
      .catch(err => {
        console.error(err)
        return null
      })

    //return orderId;

    // const orderDetailsId = { ...orderDetail, Id: orderId };
    // //orderDetailsId?.itemData.ID = orderId;
    // const updateOrderDetails = await orderRef
    //   .doc(orderId)
    //   .set(orderDetailsId)
    //   .then(() => {
    //     return orderDetailsId;
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     return null;
    //   });

    return await updateOrderDetails
  }

  async getOrderDetailsById(orderId) {
    const userId = await this.getUserID()
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)
    return await orderRef
      .doc(orderId)
      .get()
      .then(response => {
        const docData = response.data()
        if (docData.userId === userId) return docData
        return null
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  async getOrderDetailByAdmin(orderId) {
    const userId = await this.getUserID()
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)
    return await orderRef
      .doc(orderId)
      .get()
      .then(response => {
        const docData = response.data()
        return docData
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  async updateOrderDetails(orderDetails) {
    const userId = await this.getUserID()
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)

    const dbOrderDetails = await orderRef
      .doc(orderDetails.Id)
      .get()
      .then(doc => {
        const docData = doc.data()
        if (docData?.userId === userId) {
          return docData
        }
      })

    if (dbOrderDetails) {
      return await orderRef
        .doc(orderDetails.Id)
        .update(orderDetails)
        .then(async () => {
          return await orderRef
            .doc(orderDetails.Id)
            .get()
            .then(doc => doc.data())
        })
        .catch(err => {
          return err.message
        })
    }
  }

  async updateOrderDetailsByAdmin(orderDetails) {
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)

    return await orderRef
      .doc(orderDetails.Id)
      .update(orderDetails)
      .then(async () => {
        return await orderRef
          .doc(orderDetails.Id)
          .get()
          .then(doc => doc.data())
      })
      .catch(err => {
        return err.message
      })
  }

  async getOrderList() {
    const userId = await this.getUserID()
    return await firebase.db
      .collection(this.ORDER_COLLECTION)
      .where("userId", "==", userId)
  }

  async retrieveCommonOrders() {
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)

    return await orderRef
      .where("Identifier", "in", [
        "R6ZxDYTSRhGWHUPOtX8l",
        "37wce3dng5hGtd0PZf1v",
      ])
      .get()
      .then(response => {
        return response.docs.map(doc => doc.data())
      })
      .catch(err => {
        return null
      })
  }

  async deleteOrder(orderId) {
    const userId = await this.getUserID()
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)
    return await orderRef
      .doc(orderId)
      .get()
      .then(async doc => {
        const docData = doc.data()
        if (docData?.userId === userId) {
          return orderRef
            .doc(orderId)
            .delete()
            .then(() => {
              return { status: 200 }
            })
        }
      })
      .catch(err => {
        return err.message
      })
  }

  async getOrderListBySpecId(specId) {
    const userId = await this.getUserID()
    return await firebase.db
      .collection(this.ORDER_COLLECTION)
      .where("userId", "==", userId)
      .where("specificationId", "==", specId)
      .get()
      .then(response => {
        return response.docs.map(doc => doc.data())
      })
      .catch(err => {
        return null
      })
  }

  /**
   * End of Order
   */

  /**
   * Specification
   */

  async createSpecification(specificationDetails) {
    console.log({ specificationDetails })
    const userId = await this.getUserID()
    const specRef = firebase.db.collection(this.SPEC_COLLECTION)
    const createSpec = await specRef.add({ ...specificationDetails, userId })
    const specId = createSpec?.id
    localStorage.setItem("specId", createSpec.id)
    const specDetails = await specRef
      .doc(specId)
      .get()
      .then(doc => doc.data())

    const specDetailsId = { ...specDetails, templateId: specId }

    const updateSpecDetails = await specRef
      .doc(specId)
      .set(specDetailsId)
      .then(() => {
        return specDetailsId
      })
      .catch(err => {
        console.error(err)
        return null
      })

    return await updateSpecDetails
  }

  async getSpecificationList() {
    const userId = await this.getUserID()
    const specRef = firebase.db.collection(this.SPEC_COLLECTION)
    return await specRef
      .where("userId", "==", userId)
      .get()
      .then(response => {
        return response.docs.map(doc => doc.data())
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  async getOrdersLinkedToSpecId(specId) {
    const userId = await this.getUserID()
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)
    return await orderRef
      .where("userId", "==", userId)
      .where("specificationId", "==", specId)
      .get()
      .then(response => {
        return response.docs.map(doc => doc.data())
      })
      .catch(err => {
        return null
      })
  }

  async getSpecificationDetailsById(specId) {
    if (!specId) return null
    const userId = await this.getUserID()
    const specRef = firebase.db.collection(this.SPEC_COLLECTION)
    return await specRef
      .doc(specId)
      .get()
      .then(response => {
        const docData = response.data()
        if (docData?.userId === userId) return docData
        return null
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  async updateSpecificationDetails(spec) {
    const userId = await this.getUserID()
    const specRef = firebase.db.collection(this.SPEC_COLLECTION)

    const specDetails = await specRef
      .doc(spec.templateId)
      .get()
      .then(doc => {
        const docData = doc.data()
        if (docData?.userId === userId) {
          return docData
        }
      })

    if (specDetails) {
      return await specRef
        .doc(spec.templateId)
        .update(spec)
        .then(async () => {
          return await specRef
            .doc(spec.templateId)
            .get()
            .then(doc => doc.data())
        })
        .catch(err => {
          return err.message
        })
    }
  }

  async deleteSpecificationbyId(specId) {
    const userId = await this.getUserID()
    const specRef = firebase.db.collection(this.SPEC_COLLECTION)
    return await specRef
      .doc(specId)
      .get()
      .then(async doc => {
        const docData = doc.data()
        if (docData?.userId === userId) {
          return specRef
            .doc(specId)
            .delete()
            .then(() => {
              return { status: 200 }
            })
        }
      })
      .catch(err => {
        return err.message
      })
  }

  async getAdminSpecDetailsById(specId) {
    const specRef = firebase.db.collection(this.SPEC_COLLECTION)
    return await specRef
      .doc(specId)
      .get()
      .then(response => {
        const docData = response.data()
        return docData
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  /**
   * End of Specification
   */

  /**
   * Start of Color Details
   */
  async createUserColorDetails(colorDetail) {
    const userId = await this.getUserID()
    const colorRef = firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_COLORS)
    const createColor = await colorRef
      .doc(colorDetail.hex)
      .set({ ...colorDetail })

    return createColor
  }

  async getColorList() {
    const userId = await this.getUserID()
    const colorRef = firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_COLORS)

    return colorRef
  }

  async getColorDetailsById(colorId) {
    const userId = await this.getUserID()
    const colorRef = firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_COLORS)
    return await colorRef
      .doc(colorId)
      .get()
      .then(response => {
        const docData = response.data()
        if (docData?.userId === userId) return docData
        return null
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  async updateColorDetails(color) {
    const userId = await this.getUserID()
    const colorRef = firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_COLORS)

    const colorDetails = await colorRef
      .doc(color.id)
      .get()
      .then(doc => {
        const docData = doc.data()
        if (docData?.userId === userId) {
          return docData
        }
      })

    if (colorDetails) {
      return await colorRef
        .doc(color.id)
        .update(color)
        .then(async () => {
          return await colorRef
            .doc(color.id)
            .get()
            .then(doc => doc.data())
        })
        .catch(err => {
          return err.message
        })
    }
  }

  async deleteColorDetailById(colorId) {
    const userId = await this.getUserID()
    const colorRef = firebase.db
      .collection(this.USER_COLLECTION)
      .doc(userId)
      .collection(this.USER_COLORS)
    return await colorRef
      .doc(colorId)
      .delete()
      .then(() => {
        return { status: 200 }
      })
  }

  /**
   * End of Color Details
   */

  /**
   * Stripe Checkout
   */

  async updatePaymentDetails(orderId) {
    const updateOrderPostPayment = firebase.functions.httpsCallable(
      "updateOrderPostPayment",
    )
    this.getOrderDetailByAdmin(orderId).then(response => {
      const userDetails = this.getUserDetailsById(response.userId)
      //Call firebase function to check if the payment is successfull from stripe
      updateOrderPostPayment({
        orderId: response.Id,
        sessionId: response.paymentSessionId,
        turnAroundTime: response.turnAroundTime,
        paymentStatus: response.paymentStatus,
        status: response.status,
        userId: response.userId,
        chargeableImages: response.chargeableImages,
        trialImages: userDetails.trialImages,
      })
    })
  }

  async retrievePaymentList() {
    const userId = await this.getUserID()
    const userDetails = await this.getUserDetailsById(userId)
    const retrieveUserPayments = firebase.functions.httpsCallable(
      "retrieveUserPayments",
    )
    return retrieveUserPayments({
      stripeId: userDetails.stripeId,
    }).then(response => {
      if (response.data) return response.data
      else return null
    })
  }

  async createStripeUser(email, name, userId) {
    const createStripeUser = firebase.functions.httpsCallable(
      "createStripeUser",
    )

    createStripeUser({
      email: email,
      name: name,
      userId: userId,
    })
  }

  async testStripe() {
    const emailId = await this.getUserEmailID()
    const stripePromise = loadStripe(this.STRIPE_PUBLIC_KEY)
    const stripe = await stripePromise
    const createCheckoutSession = firebase.functions.httpsCallable(
      "createCheckoutSession",
    )
    //Must update with the correct variable i.e thumbnail image
    // const images = [orderDetail.thumbnail]

    //Must update with correct values from orderDetails for
    // product name,quantity, price(unit_amount)
    createCheckoutSession({
      email: emailId,
      product_name: "Apparel",
      quantity: 1,
      unit_amount: 10,
    })
      .then(async response => {
        const sessionId = response.data.id
        //paymentId is unique for every session created
        const paymentId = response.data.payment_intent
        //Update OrderDetail with the payment id
        // orderDetail.paymentId = paymentId
        // await orderRef.doc(orderId).update(orderDetail)
        //Redirect to stripe Checkout page
        stripe.redirectToCheckout({ sessionId }).then(result => {
          // If `redirectToCheckout` fails due to a browser or network
          // error `error.message` will return the error message.
          if (result.error) {
            alert(result.error.message)
          }
        })
      })
      .catch(e => console.log("Error Making Payment", e))
  }

  async checkoutOrderWithStripe(orderId, currency) {
    const userId = await this.getUserID()
    const emailId = await this.getUserEmailID()
    const orderRef = firebase.db.collection(this.ORDER_COLLECTION)
    //Get the Order details from DB for the Order Id
    const orderDetail = await orderRef
      .doc(orderId)
      .get()
      .then(doc => {
        const docData = doc.data()
        if (docData?.userId === userId) {
          return docData
        }
      })
    //Proceed with payment only if the userId in Orders and logged in userid are same
    if (orderDetail) {
      const stripePromise = loadStripe(this.STRIPE_PUBLIC_KEY, {
        automatic_tax: {
          enabled: true,
        },
      })
      const stripe = await stripePromise
      const createCheckoutSession = firebase.functions.httpsCallable(
        "createCheckoutSession",
      )
      //Must update with the correct variable i.e thumbnail image
      //const images = [orderDetail.thumbnail]
      await firebase.db
        .collection(this.USER_COLLECTION)
        .doc(orderDetail.userId)
        .get()
        .then(userResponse => {
          //Must update with correct values from orderDetails for
          // product name,quantity, price(unit_amount)
          createCheckoutSession({
            email: emailId,
            customerId: userResponse.data().stripeId,
            product_name: orderDetail.name,
            quantity: 1,
            unit_amount: orderDetail.totalPrice,
            currency: currency === "ca" ? "cad" : "usd",
            orderId: orderId,
            userName: userResponse.data().fullname,
            turnAroundTime: orderDetail.turnAroundTime,
          })
            .then(async response => {
              const sessionId = response.data.id
              //paymentId is unique for every session created
              const paymentId = response.data.payment_intent
              //Update OrderDetail with the payment id
              orderDetail.paymentId = paymentId
              orderDetail.paymentSessionId = sessionId
              orderDetail.paymentStatus = "notPaid"
              await orderRef.doc(orderId).update(orderDetail)
              //Redirect to stripe Checkout page
              stripe.redirectToCheckout({ sessionId }).then(result => {
                // If `redirectToCheckout` fails due to a browser or network
                // error `error.message` will return the error message.
                if (result.error) {
                  alert(result.error.message)
                }
              })
            })
            .catch(e => console.log("Error Making Payment", e))
        })
    }
  }

  /**
   * Function Calls
   */

  async createFunctionCall(functionDetails) {
    const functionRef = firebase.db
      .collection(this.FUNCTION_CALL_COLLECTION)
      .doc(functionDetails.userId)
      .collection(this.FUNCTION_CALL_DETAIL)
      .doc(functionDetails.orderId)
    const functionDetailsDB = await functionRef.set({ ...functionDetails })
    return functionDetailsDB
  }

  // async getSpecDetails() {
  //   const details=[];
  //   const userId = await this.getUserID();
  //   const specRef = firebase.db.collection(this.SPEC_COLLECTION);
  //   specRef.get()
  //     .then((response) => {
  //       const specList= response.docs.map((doc) => doc.data());
  //       specList.forEach((spec) =>{
  //         let data={
  //           specId:spec.templateId,
  //           specName:spec.name,
  //           userId:spec.userId,
  //         }

  //         details.push(data);
  //       })
  //       const uniqueUserId = [...new Set(details.map(item => item.userId))];
  //       console.log(details);
  //       console.log(uniqueUserId);
  //     })
  //     .catch((err) => {
  //       return null;
  //     });
  // }

  /**
   * End of Function Calls
   */
}

const firebase = new Firebase()
export default firebase
