import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import firebase from "../../firebase/firebase"
import OrderItem from "./OrderItem"
import { Link } from "react-router-dom"
import { Card, Alert } from "react-bootstrap"
import { BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs"
import { Divider } from "@material-ui/core"
import { Modal, Button } from "react-bootstrap"
import moment from "moment"

export default function OrderList(props) {
  const user = useSelector(state => state.auth.user, shallowEqual)
  const isAdmin = user?.roles[0] === 1000 ? true : false
  var [orderList, setOrderList] = useState([])
  const [isOrderIdUp, setOrderIdUp] = useState(false)
  const [isOrderNameUp, setOrderNameUp] = useState(false)
  const [isDateUp, setDateUp] = useState(false)
  const [isStatusUp, setStatusUp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)

  async function fetchOrderList() {
    const userOrderList = isAdmin ? await firebase.retrieveAllOrders() : await firebase.getOrderList()
    userOrderList.onSnapshot(snapshot => {
      const response = snapshot.docs.map(doc => ({
        ...doc.data(),
      }))
      setOrderList(response.sort((a, b) => b.createdDate - a.createdDate))
    })
  }

  function updateOrderPaymentStatus(props) {
    if (props?.location?.search) {
      const params = new URLSearchParams(props.location.search)

      const paymentOrderId = params.get("orderId")
      const paymentStatus = params.get("payment")

      if (paymentOrderId && paymentStatus === "success") {
        firebase.updatePaymentDetails(paymentOrderId)
      }
    }
  }

  function handleDelete(orderId) {
    setLoading(true)
    firebase.deleteOrder(orderId).then(deletedOrder => {
      if (deletedOrder?.status === 200) {
        fetchOrderList()
      }
    })
  }

  const handleSort = column => {
    if (column === "orderId") {
      console.log(isOrderIdUp)
      if (isOrderIdUp) {
        setOrderIdUp(false)
        orderList.sort(function (a, b) {
          if (a.Id.toLowerCase() === b.Id.toLowerCase()) return 0
          else if (a.Id.toLowerCase() < b.Id.toLowerCase()) return 1
          return -1
        })
      } else {
        orderList.sort(function (a, b) {
          if (a.Id.toLowerCase() === b.Id.toLowerCase()) return 0
          else if (b.Id.toLowerCase() < a.Id.toLowerCase()) return 1
          return -1
        })
        setOrderIdUp(true)
      }
    } else if (column === "orderName") {
      if (isOrderNameUp) {
        setOrderNameUp(false)
        orderList.sort(function (a, b) {
          if (a.name.toLowerCase() === b.name.toLowerCase()) return 0
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return 1
          return -1
        })
      } else {
        orderList.sort(function (a, b) {
          if (a.name.toLowerCase() === b.name.toLowerCase()) return 0
          else if (b.name.toLowerCase() < a.name.toLowerCase()) return 1
          return -1
        })
        setOrderNameUp(true)
      }
    } else if (column === "date") {
      if (isDateUp) {
        orderList.sort((a, b) => a.createdDate - b.createdDate)
        setDateUp(false)
      } else {
        orderList.sort((a, b) => b.createdDate - a.createdDate)
        setDateUp(true)
      }
    } else if (column === "status") {
      if (isStatusUp) {
        orderList.sort(function (a, b) {
          if (a.status === b.status) return 0
          else if (a.status < b.status) return 1
          return -1
        })
        setStatusUp(false)
      } else {
        orderList.sort(function (a, b) {
          if (a.status === b.status) return 0
          else if (b.status < a.status) return 1
          return -1
        })
        setStatusUp(true)
      }
    }
  }

  let count = 1
  useEffect(() => {
    async function fetchData() {
      if (isAdmin) {
        console.log(isAdmin, "isAdmin")
        const orderList = await firebase.retrieveAllOrders()
        orderList.onSnapshot(async snapshot => {
          let response = snapshot.docs.map(doc => ({
            ...doc.data(),
          }))

          const userList = await firebase.getAllUsers();
          userList.onSnapshot(async snapshot1 => {
            const userResponse = snapshot1.docs.map(doc => ({
              ...doc.data(),
            }))


            response = response.map(res => {
              console.log("🚀 ~ file: OrderList.js:142 ~ fetchData ~ res:", res)
              let orderUser = userResponse.find(uRes => uRes.id === res.userId)
              if (orderUser) {
                res.userName = orderUser.fullname;
                res.email = orderUser.email;
              }
              return res;
            })
          })


          console.log("🚀 ~ file: OrderList.js:142 ~ fetchData ~ response:", response)


          setOrderList(response.sort((a, b) => b.createdDate - a.createdDate))
        })
      } else {
        const userOrderList = await firebase.getOrderList()
        userOrderList.onSnapshot(snapshot => {
          const response = snapshot.docs.map(doc => ({
            ...doc.data(),
          }))

          setOrderList(response.sort((a, b) => b.createdDate - a.createdDate))
        })
        if (props.hideCreate == true) {
          userOrderList.onSnapshot(snapshot => {
            const response = snapshot.docs.map(doc => ({
              ...doc.data(),
            }))

            setOrderList(
              response
                .sort((a, b) => b.createdDate - a.createdDate)
                .filter(response => response.status === "Ready"),
            )
          })
        }
      }
    }

    updateOrderPaymentStatus(props)
    fetchData()
  }, [])

  console.log("🚀 ~ file: OrderList.js:16 ~ OrderList ~ orderList:", orderList)

  let sync_date = null, sync_timestamp = null, sync_datetime = null;
  let date = new Date();
  sync_date = moment(date).format("YYYY-MM-DD");
  sync_timestamp = date.getTime()
  sync_datetime = moment(date).format("YYYY-MM-DDTHH:mm:ss");

  let newArry = []

  for (let index = 0; index < orderList.length; index++) {
    const element = orderList[index];
    const now = new Date(); // Get the current date and time
    const timestamp = now.getTime(); // Convert the current date and time to a timestamp
    now.setDate(now.getDate() - 60); // Subtract 60 days from the date
    const sixtyDaysOldTimestamp = now.getTime(); // Convert the resulting date back to a timestamp
    let currentTimeStamp = sync_timestamp

    if (element.createdDate > sixtyDaysOldTimestamp) {
      newArry.push(element)
    }

    if (index + 1 == orderList.length) {
      console.log("🚀 ~ file: OrderList.js:201 ~ OrderList ~ newArry:", newArry)
      console.log("🚀 ~ file: OrderList.js:201 ~ OrderList ~ newArry.length:", newArry.length)

    }

  }

  orderList = newArry

  return (
    <>

      <Card className={`card-custom gutter-b`} style={{ height: "100%", 'position': 'relative' }}>
        <Card.Header className={`py-5`}>
          <h3 className="card-title align-items-start flex-column">
            {props.hideCreate ? (
              <span
                className="card-label font-weight-bolder text-dark"
                style={{ fontSize: "2.2rem" }}
              >
                Completed Orders
              </span>
            ) : (
              <span
                className="card-label font-weight-bolder text-dark"
                style={{ fontSize: "2.2rem" }}
              >
                Orders
              </span>
            )}
            {props.hideCreate ? (
              <span
                className="Update your personal informaitonmt-3 font-weight-bold font-size-sm"
                style={{ fontSize: "1.3rem" }}
              >
                List of all the Orders that are ready
              </span>
            ) : (
              <span className="mt-3 font-size-sm smlhed" style={{ 'opacity': "70%" }}>
                List of all the Orders created by you
              </span>
            )}{" "}
          </h3>
          <div className="card-toolbar" style={{ marginRight: '22rem' }}>
            {!isAdmin ? (
              <Link to="/upload" >
                {!props.hideCreate && (
                  <button className="btn btn-primary font-weight-bolder font-size-sm">
                    Create
                  </button>
                )}
              </Link>
            ) : null}
          </div>
        </Card.Header>

        {/* <Card>
          <p style={{ marginLeft: "2rem", fontSize: "1.5rem" }}>
            There are no orders that are currently ready
          </p>
        </Card>
       */}
        <Card.Body>
          {orderList.length == 0 && props.hideCreate ? (
            <Alert className="mt-5 text-center" style={{ fontSize: "1.2rem" }}>
              There are no Orders ready for download. You can create a new Order
              <Alert.Link className="ml-2" href="/upload">
                here
              </Alert.Link>
            </Alert>
          ) : (
            //<p>There are no orders that are ready </p>



            < table className="table table-head-custom table-vertical-center table-head-bg table-borderless">
              <thead>
                <tr
                  className="text-center"
                  style={{
                    display: "grid",
                    // gridTemplateColumns: "5% 15.5% 15.5% 15.5% 15.5% 15.5% 15%",
                    gridTemplateColumns: "3% 10% 10% 15% 16% 18% 18% 12% 18%",
                    width: "85%",
                  }}
                >
                  <th>
                    <span className="text-dark-75">#</span>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("orderId")}
                  // style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="text-dark-75">Order Id</div>{" "}
                    <div style={{ cursor: "pointer" }}>
                      <BsFillCaretUpFill id="orderIdUp" />
                      <BsFillCaretDownFill id="orderIdDown" />
                    </div>
                  </th>
                  {
                    isAdmin && <>
                      <th
                        style={{ cursor: "pointer", 'font-size': '11px' }}
                        onClick={() => handleSort("userName")}
                      // style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div className="text-dark-75">User Name</div>{" "}
                        <div style={{ cursor: "pointer", fontSize: '11px' }}>
                          <BsFillCaretUpFill id="userNameUp" />
                          <BsFillCaretDownFill id="userNameDown" />
                        </div>
                      </th>

                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("email")}
                      // style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div className="text-dark-75">Email</div>{" "}
                        <div style={{ cursor: "pointer" }}>
                          <BsFillCaretUpFill id="emailUp" />
                          <BsFillCaretDownFill id="emailDown" />
                        </div>
                      </th>
                    </>
                  }

                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("orderName")}
                  // style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="text-dark-75">Name</div>{" "}
                    <div style={{ cursor: "pointer" }}>
                      <BsFillCaretUpFill id="orderNameUp" />
                      <BsFillCaretDownFill id="orderNameDown" />
                    </div>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("date")}
                  // style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="text-dark-75">Order Date</div>{" "}
                    <div style={{ cursor: "pointer" }}>
                      <BsFillCaretUpFill id="dateUp" />
                      <BsFillCaretDownFill id="dateDown" />
                    </div>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("date")}
                  // style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="text-dark-75">Due Date</div>{" "}
                    <div style={{ cursor: "pointer" }}>
                      <BsFillCaretUpFill id="dateUp" />
                      <BsFillCaretDownFill id="dateDown" />
                    </div>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("status")}
                  // style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="text-dark-75">Status</div>{" "}
                    <div style={{ cursor: "pointer" }}>
                      <BsFillCaretUpFill id="statusUp" />
                      <BsFillCaretDownFill id="statusDown" />
                    </div>
                  </th>
                  <th>
                    <span className="text-dark-75">Actions</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {orderList.map(item => {
                  return (
                    <OrderItem
                      key={item.Id}
                      // key={item.orderId}
                      ordercount={count++}
                      orderItem={item}
                      hideCreate={props.hideCreate}
                    // handleDelete={handleDelete}
                    />
                  )
                })}
              </tbody>
            </table>




          )}
        </Card.Body>
      </Card >




      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            An account already exists with the same email address but different
            sign-in credentials. You can link the existing account with facebook
            by clicking "Link accounts" or click "Cancel" and login using
            Google.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button variant="primary">Link Accounts</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
