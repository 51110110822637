import React, { useState, useEffect, useContext } from "react"
import { useFormik } from "formik"
import { connect, useDispatch } from "react-redux"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import { FormattedMessage, injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { login, register } from "../_redux/authCrud"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import firebase from "../../../firebase/firebase"
import GoogleButton from "react-google-button"
import userDetails from "../__mocks__/userDetails"
import { userContext } from "../../../../_metronic/layout/_core/MetronicUserLoginProvider"
import { Card } from "react-bootstrap"
import "./styles/login.css"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

const initialValues = {
  fullname: "",
  email: "",
  // username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
}

const userInitialisationDetail = {
  id: "",
  // username: "",
  password: "",
  firstTimerUser: true,
  currency: "ca",
  creationDate: new Date().getTime(),
  email: "",
  plan: "",
  accessToken: "access-token-" + Math.random(),
  refreshToken: "access-token-" + Math.random(),
  roles: [2],
  pic: process.env.PUBLIC_URL + "/media/users/default.jpg",
  fullname: "",
  firstname: "",
  lastname: "",
  occupation: "",
  companyName: "",
  phone: "",
  language: "en",
  timeZone: "International Date Line West",
  website: "",
  trialImages: 10,
  emailSettings: {
    emailNotification: true,
    sendCopyToPersonalEmail: false,
    activityRelatesEmail: {
      youHaveNewNotifications: false,
      youAreSentADirectMessage: false,
      someoneAddsYouAsAsAConnection: true,
      uponNewOrder: false,
      newMembershipApproval: false,
      memberRegistration: true,
    },
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: false,
      tipsOnGettingMoreOutOfKeen: false,
      thingsYouMissedSindeYouLastLoggedIntoKeen: true,
      newsAboutMetronicOnPartnerProductsAndOtherServices: true,
      tipsOnMetronicBusinessProducts: true,
    },
  },
  communication: {
    email: true,
    sms: true,
    phone: false,
  },
  address: {
    addressLine: "",
    city: "",
    state: "",
    postCode: "",
  },
  socialNetworks: {
    linkedIn: "https://linkedin.com/admin",
    facebook: "https://facebook.com/admin",
    twitter: "https://twitter.com/admin",
    instagram: "https://instagram.com/admin",
  },
}

function Registration(props) {
  const params = new URLSearchParams(props.location.search)
  const plan = params.get("plan")
  const { setFirstTimeUserLogin } = useContext(userContext)
  const dispatch = useDispatch()
  const { intl } = props
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    // username: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    password: Yup.string()
      .min(6, "Minimum 6 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      )
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match",
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions",
    ),
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      enableLoading()
      authenticateAndRegisterUser(setStatus, setSubmitting, values)
    },
  })

  const postAuth = (response, values) => {
    const user = userInitialisationDetail
    user.id = response.user.uid
    // user.username = values.username;
    user.password = values.password
    user.email = values.email
    if (plan === "" || plan === null) user.plan = "pro"
    else user.plan = plan
    user.fullname = values.fullname
    // user.firstname = values.username;
    user.firstname = values.fullname
    firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .set(user)
      .catch(e => {
        console.log(e)
      })
    firebase.createStripeUser(values.email, values.fullname, response.user.uid)
    sendWelcomeEmail(values.email, values.fullname)
    return user
  }

  const sendWelcomeEmail = (email, fullname) => {
    const sendWelcomeEmail = firebase.functions.httpsCallable(
      "sendWelcomeEmail",
    )
    sendWelcomeEmail({
      email: email,
      userName: fullname,
    })
  }

  const postSocialMediaAuth = (accessToken, response, provider) => {
    const user = userDetails.find(x => x.accessToken === accessToken)
    if (plan === "" || plan === null) user.plan = "pro"
    else user.plan = plan
    user.id = response.user.uid
    user.pic =
      provider === "facebook"
        ? response.user.photoURL + "?height=500"
        : response.user.photoURL
    userDetails.splice(0, userDetails.length)
    userDetails.push(user)
    setUserLocation(user)
    firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .set(user)
      .catch(e => {
        console.log(e)
      })
    firebase.createStripeUser(user.email, user.fullname, response.user.uid)
    sendWelcomeEmail(user.email, user.fullname)
  }

  const setUserLocation = user => {
    //Set currency to CAD only when the user allowes to give location
    //else the default currency is USD

    //get the lat and lan
    navigator.geolocation.getCurrentPosition(
      function(position) {
        //get the address using Google Geocoding API
        fetch(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "&key=" +
            firebase.GOOGLE_API_KEY,
        )
          .then(res => res.json())
          .then(response => {
            //Get only the country name from the response list
            const country = response.results[0].address_components.find(
              address => address.types[0] === "country",
            )
            user.currency =
              country.long_name.toLowerCase() === "canada" ? "ca" : "us"
            //update local user data with currency
            userDetails.splice(0, userDetails.length)
            userDetails.push(user)
            dispatch(props.setUser(user))
            //update in backend with user currency
            firebase.db
              .collection(firebase.USER_COLLECTION)
              .doc(user.id)
              .set(user)
              .catch(e => {
                console.log(e)
              })
          })
          .catch(error => {
            console.log(error)
          })
      },
      function(error) {
        console.log(error)
      },
    )
  }

  async function signInWithSocialMedia(provider) {
    userDetails.splice(0, userDetails.length)
    try {
      await firebase.socialLogin(provider).then(response => {
        loginOrRegisterSocialUser(response, provider)
      })
    } catch (err) {
      formik.setStatus(err.message)
    }
  }

  async function loginOrRegisterSocialUser(response, provider) {
    await firebase.db
      .collection(firebase.USER_COLLECTION)
      .doc(response.user.uid)
      .get()
      .then(user => {
        if (user.exists) {
          userDetails.push(user.data())
          login(user.data().email, user.data().password).then(
            ({ data: { accessToken } }) => {
              disableLoading()
              props.login(accessToken)
            },
          )
        } else {
          const givenName =
            provider === "google"
              ? response.additionalUserInfo.profile.given_name
              : response.additionalUserInfo.profile.name
          register(
            response.additionalUserInfo.profile.email,
            response.additionalUserInfo.profile.name,
            givenName,
            response.additionalUserInfo.profile.id,
          )
            .then(({ data: { accessToken } }) => {
              props.register(accessToken)

              disableLoading()
              setFirstTimeUserLogin(true) // user logs in first time throguh gmail login
              postSocialMediaAuth(accessToken, response, provider)
            })
            .catch(e => {
              disableLoading()
            })
        }
      })
      .catch(() => {
        formik.setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          }),
        )
      })
  }

  async function authenticateAndRegisterUser(setStatus, setSubmitting, values) {
    await firebase.auth
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(async response => {
        var user = postAuth(response, values)
        //This is to determine the Redirect URL from email verification
        var actionCodeSettings = {
          // URL you want to redirect back to. The domain for this
          // URL must be in the authorized domains list in the Firebase Console.
          url:
            firebase.REDIRECT_URL +
            "?email=" +
            values.email +
            "&access=" +
            user.accessToken,
          // This must be true.
          handleCodeInApp: true,
        }
        await response.user
          .sendEmailVerification(actionCodeSettings)
          .then(response => {
            setSubmitting(false)
            disableLoading()
            formik.resetForm()
            setModalShow(true)
          })
          .catch(() => {
            setSubmitting(false)
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              }),
            )
            disableLoading()
          })
      })
      .catch(e => {
        setSubmitting(false)
        setStatus(e.message)
        disableLoading()
        return false
      })
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Welcome to Pixhoto!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A verification link is sent to your registered email address. Please
            click on the link before logging into Pixhoto.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        <ul className="socilgi">
          <li>
            <GoogleButton
              style={{ width: "70", float: "none", position: "static" }}
              onClick={() => signInWithSocialMedia("google")}
            />
          </li>
          <li>
            <div
              onClick={() => signInWithSocialMedia("facebook")}
              style={{ display: "block" }}
            >
              <a className="btn btn-block btn-social btn-facebook">
                <i className="fab fa-facebook fa-fw fa-pull-left"></i> Sign in
                with Facebook
              </a>
            </div>
          </li>
        </ul>

        <p className="orword">Or</p>

        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Full name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "fullname",
            )}`}
            name="fullname"
            {...formik.getFieldProps("fullname")}
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email",
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="User name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password",
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword",
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
              height="2rem"
              width="2rem"
            />
            <a
            className="terms-conditions"
              onClick={() =>
                window.open(
                  "https://pixhoto.com/terms-of-service/",

                  "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=800, top=100, left=400",
                )
              }
              target="_parent"
              href="javascript: void(0)"
            >
              I have read and accept the terms and conditions.
            </a>{" "}
            &nbsp;&nbsp;
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            style=    {{"cursor":   
              (formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms)
             ?  "not-allowed":undefined}}
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          {/* <Link to="/auth/login"> */}
          <Link
            to={{
              pathname: "/auth/login",
              search: "plan=" + plan,
            }}
          >
            {/* <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
            */}
          </Link>
        </div>
        <div></div>
        <Card
          className="d-flex d-lg-none flex-column-auto flex-column align-items-center mt-5 p-5 "
          style={{ border: "0.5px solid #b0b0b0" }}
        >
          <p>
            The Pixhoto platform is currently not designed for use on mobile
            devices.
          </p>
          <p>Please use the platform on a desktop for an optimal experience.</p>
        </Card>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Registration))
