import React, { useState } from "react"

const userContext = React.createContext()

function MetronicUserLoginProvider({ children }) {
  const [firstTimeUserLogin, setFirstTimeUserLogin] = useState(false)

  return (
    <userContext.Provider value={{ firstTimeUserLogin, setFirstTimeUserLogin }}>
      {children}
    </userContext.Provider>
  )
}

export { userContext, MetronicUserLoginProvider }
