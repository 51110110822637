/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import axios from "axios"
import * as _redux from "./redux"
import store, { persistor } from "./redux/store"
import App from "./app/App"
import "./index.scss" // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css"
import "socicon/css/socicon.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./_metronic/_assets/plugins/flaticon/flaticon.css"
import "./_metronic/_assets/plugins/flaticon2/flaticon.css"
// Datepicker
import "react-datepicker/dist/react-datepicker.css"
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout"
import { MetronicCurrencyProvider } from "./_metronic/layout/_core/MetronicCurrencySelector"
import { MetronicSpecificationProvider } from "./_metronic/layout/_core/MetronicSpecificationToEdit"
import { MetronicOrderTotalProvider } from "./_metronic/layout/_core/MetronicOrderTotal"
import { MetronicUserLoginProvider } from "./_metronic/layout/_core/MetronicUserLoginProvider"
import { MetronicI18nProvider } from "./_metronic/i18n"
import TagManager from 'react-gtm-module'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ _redux.mockAxios(axios)
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)
TagManager.initialize({ gtmId: "GTM-5BQ2HPT" })
ReactDOM.render(
  <MetronicUserLoginProvider>
    <MetronicSpecificationProvider>
      <MetronicOrderTotalProvider>
        <MetronicI18nProvider>
          <MetronicCurrencyProvider>
            <MetronicLayoutProvider>
              <MetronicSubheaderProvider>
                <MetronicSplashScreenProvider>
                  <App
                    store={store}
                    persistor={persistor}
                    basename={PUBLIC_URL}
                  />
                </MetronicSplashScreenProvider>
              </MetronicSubheaderProvider>
            </MetronicLayoutProvider>
          </MetronicCurrencyProvider>
        </MetronicI18nProvider>
      </MetronicOrderTotalProvider>
    </MetronicSpecificationProvider>
  </MetronicUserLoginProvider>,
  document.getElementById("root"),
)
