/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

export default function EnhancedPlan() {
  return (
    <div className="ml-15 rounded mr-09 pr-10">
      <div className="pt-10 bg-gray-300">
        <h1 className="mb-10">Enhanced Plan</h1>

        <span className="px-7 py-3 font-size-h4 font-weight-bold d-inline-flex flex-start  rounded-lg mb-1">
          Base Price/Image <br />
          $0.98
        </span>
        <br />
        <hr />
        <span className="px-7 py-3 font-size-h4 font-weight-bold d-inline-flex flex-start  rounded-lg  ">
          Free one time trial <br />
          10 images
        </span>
        <br />
        <hr />
        <span className="px-7 py-3 font-size-h4 font-weight-bold d-inline-flex flex-start  rounded-lg ">
          Image delivery time <br /> 12-24 hrs
        </span>
        <hr />
        <span className="px-7 py-3 font-size-h4 font-weight-bold d-inline-flex flex-start  rounded-lg  ">
          Monthly Subscription <br />
          $0 For 3 months!
        </span>
        <hr />
      </div>
    </div>
  )
}
