/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react"
import clsx from "clsx"
import { Dropdown } from "react-bootstrap"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../../_helpers"
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns"
import { CurrencyContext } from "../../../../layout/_core/MetronicCurrencySelector"

const currencies = [
  {
    key: "usd",
    name: "USD",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
    isSelect: true,
  },
  {
    key: "cad",
    name: "CAD",
    flag: toAbsoluteUrl("/media/svg/flags/243-canada.svg"),
    isSelect: false,
  },
]

export function CurrencySelectorDropdown() {
  const { currentCurrency, handleChangeCurrency } = useContext(CurrencyContext)
  const userCurrency = JSON.parse(localStorage.getItem("userCurrency"))
  const [selectedCurrency, setselectedCurrency] = useState(
    userCurrency
      ? userCurrency
      : currencies.find(obj => obj.key === currentCurrency),
  )

  const handleSelect = currency => {
    setselectedCurrency(currency)
    handleChangeCurrency(currency.key)
    localStorage.setItem("userCurrency", JSON.stringify(currency))
  }

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="language-panel-tooltip">Select Currency</Tooltip>
          }
        >
          <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
            <img
              className="h-25px w-25px rounded"
              src={selectedCurrency.flag}
              alt={selectedCurrency.name}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround ">
        <ul className="navi navi-hover py-4">
          {currencies.map(currency => (
            <li
              key={currency.key}
              className={clsx("navi-item", {
                active: currency.key === selectedCurrency.key,
              })}
            >
              <a onClick={() => handleSelect(currency)} className="navi-link">
                <span className="symbol symbol-20 mr-3">
                  <img src={currency.flag} alt={currency.name} />
                </span>
                <span className="navi-text">{currency.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}
