import JSZip from "jszip"
import { saveAs } from "file-saver"
import JSZipUtils from "jszip-utils"
import firebase from "../../firebase/firebase"

export default function downloadImageAsZip(
  user,
  orderId,
  orderDetails,
  latestImageArr,
) {
  const isAdmin = user?.roles[0] === 1000 ? true : false

  firebase.getOrderDetailByAdmin(orderId).then(response => {
    if (isAdmin) {
      adminDownload(orderId, response)
    } else {
      userDownload(orderId, response, latestImageArr)
    }
    return "complete"
  })
}
const userDownload = (orderId, orderDetails, latestImageArr) => {
  var zip = new JSZip()
  var count = 0
  var zipFilename = orderDetails.name + "_" + orderId + ".zip"
  const adminImages = latestImageArr.filter(image =>
    image["tags"].find(obj => obj.tagName === "adminUploadTag"),
  )
  adminImages.forEach(function(image) {
    var folderName = ""
    if (image.version == 0) {
      folderName = "Order Version"
    } else {
      folderName = "Order Version " + image.version
    }
    let folder = zip.folder(folderName)
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(image.src, function(err, data) {
      if (err) {
        throw err // or handle the error
      }
      const filename = image.imageName + "." + image.imageExtension
      //load images in folder

      folder.file(filename, data, { binary: true })
      count++
      //zip.file(filename, data, { binary: true });
      if (count === adminImages.length) {
        count = 0
        // userDownload(orderId, orderDetails);
        zip.generateAsync({ type: "blob" }).then(function(content) {
          saveAs(content, zipFilename)
        })
      }
    })
  })
}

const adminDownload = (orderId, orderDetails) => {
  var zip = new JSZip()
  var count = 0
  var zipFilename = orderDetails.name + "_" + orderId + ".zip"
  orderDetails.images.forEach(function(image) {
    var filename = image.imageName + "." + image.imageExtension
    var folderName = ""

    if (
      image.version == 0 &&
      !image["tags"].find(obj => obj.tagName === "adminUploadTag")
    ) {
      folderName = "User Images"
    } else if (
      image.version == 0 &&
      image["tags"].find(obj => obj.tagName === "adminUploadTag")
    ) {
      folderName = "Order Version"
    } else {
      folderName = "Order Version " + image.version
    }
    let folder = zip.folder(folderName)
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(image.src, function(err, data) {
      if (err) {
        throw err // or handle the error
      }

      //load images in folder
      folder.file(filename, data, { binary: true })
      count++
      //zip.file(filename, data, { binary: true });
      if (count == orderDetails.images.length) {
        count = 0
        zip.generateAsync({ type: "blob" }).then(function(content) {
          saveAs(content, zipFilename)
        })
      }
    })
  })
}
