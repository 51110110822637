import React, { useState, useContext, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import firebase from "../../firebase/firebase"
import { useSelector, shallowEqual } from "react-redux"
import DeleteSVG from "../Common/components/svg/DeleteSVG"
import downloadImageAsZip from "./DownloadImages"
import { PDFExport } from "@progress/kendo-react-pdf"
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { AdminUpload } from "./AdminUpload"
import moment from "moment"
import LoadingOverlay from "react-loading-overlay"
import styled, { css } from "styled-components"
import { CurrencyContext } from "../../../_metronic/layout/_core/MetronicCurrencySelector"
import { BsFillFlagFill } from "react-icons/bs"
import { isNull } from "lodash"



export default function OrderItem({ ordercount, orderItem, hideCreate }) {
  // const [modalShow, setModalShow] = useState(false);
  const [groupTagList, setGroupTagList] = useState([])
  const [annotationList, setAnnotationList] = useState([])
  const [referenceTagList, setReferenceTagList] = useState([])
  const [colorTagList, setColorTagList] = useState([])
  const [externalColorTagList, setExternalColorTagList] = useState([])
  const [colorWaysTagList, setColorWaysTagList] = useState([])
  const [mannequinTagList, setMannequinTagList] = useState([])
  const [specDetails, setSpecDetails] = useState({})
  // console.log(orderItem, 'in orderItem.Id')
  // const ref = React.createRef();
  const pdfExportComponent = React.useRef(null)
  let orderStatus = ""
  // orderItem.StatusLabel.includes("Checkout")
  //   ? (orderStatus = "-warning")
  //   : (orderStatus = "-success");
  //  console.log("orderStatus "+orderStatus);

  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")

  useEffect(() => {

    firebase.getUserDetailsById(orderItem.userId).then(response => {
      if (response) {
        setUserName(response.fullname)
        setUserEmail(response.email)
      }
    })


    if (orderItem && orderItem.images) {
      //Find out all the group tags
      let groupTags = orderItem.images.map(image => {
        return image.tags.filter(
          tag =>
            tag.tagName === "groupTag" && !(tag.title.indexOf("CF_ref") > -1),
        )
      })

      groupTags = flattenArr(groupTags)

      let groupTagsWithOutDuplicates = [
        ...new Set(groupTags.map(groupObj => groupObj.title)),
      ]

      //sort in ascending order
      groupTagsWithOutDuplicates.sort(function (a, b) {
        if (a.toLowerCase() === b.toLowerCase()) return 0
        else if (b.toLowerCase() < a.toLowerCase()) return 1
        return -1
      })

      setGroupTagList(groupTagsWithOutDuplicates)

      //find out all the reference tags
      let refTags = orderItem.images
        .map(image => {
          return image.tags.filter(tag => tag.tagName === "referenceTag")
        })
        .filter(arr => arr.length)
      refTags = flattenArr(refTags)
      refTags = refTags.map(tagObj => tagObj.title)

      setReferenceTagList(refTags)

      //find out all the color ways  tags
      let colorWaysTags = orderItem.images
        .map(image => {
          return image.tags.filter(tag => tag.tagName === "colorWaysTag")
        })
        .filter(arr => arr.length)

      colorWaysTags = flattenArr(colorWaysTags)

      colorWaysTags = colorWaysTags.map(
        tagObj => `${tagObj.value} (${tagObj.title})`,
      )
      let colorWaysWithoutDupes = [...new Set(colorWaysTags)]

      setColorWaysTagList(colorWaysWithoutDupes)
      //find out all the mannequin tags
      let manneTags = orderItem.images
        .map(image => {
          return image.tags.filter(tag => tag.tagName === "mannequinTag")
        })
        .filter(arr => arr.length)

      manneTags = flattenArr(manneTags)

      manneTags = manneTags.map(tagObj => tagObj.value)

      let manneTagsWithoutDupes = [...new Set(manneTags)]
      setMannequinTagList(manneTagsWithoutDupes)

      //find out all the color tags
      let colorTags = orderItem.images
        .map(image => {
          return image.tags.filter(tag => tag.tagName === "colorTag")
        })
        .filter(arr => arr.length)

      colorTags = flattenArr(colorTags)

      colorTags = colorTags.map(tagObj => `${tagObj.value} (${tagObj.title})`)
      let colorTagsWithoutDupes = [...new Set(colorTags)]
      setColorTagList(colorTagsWithoutDupes)

      //find out all the color tags
      let externalColorTags = orderItem.images
        .map(image => {
          return image.tags.filter(tag => tag.tagName === "colorFixLinkTag")
        })
        .filter(arr => arr.length)

      externalColorTags = flattenArr(externalColorTags)

      externalColorTags = externalColorTags.map(
        tagObj => `${tagObj.value} (${tagObj.title})`,
      )
      let externalColorTagsWithoutDupes = [...new Set(externalColorTags)]
      setExternalColorTagList(externalColorTagsWithoutDupes)

      //find out all the annoation tags
      let annoTags = orderItem.images
        .map(image => {
          return image.tags.filter(tag => tag.tagName === "annotationTag")
        })
        .filter(arr => arr.length)

      annoTags = flattenArr(annoTags)

      annoTags = annoTags.map(tagObj => `${tagObj.value} (${tagObj.title})`)
      let annoTagsWithoutDupes = [...new Set(annoTags)]
      setAnnotationList(annoTagsWithoutDupes)
    }
  }, [orderItem])

  function flattenArr(arr) {
    return arr.reduce((agg, val) => {
      return Array.isArray(val) ? [...agg, ...flattenArr(val)] : [...agg, val]
    }, [])
  }


  const user = useSelector(state => state.auth.user, shallowEqual)
  const isAdmin = user?.roles[0] === 1000 ? true : false
  const [loaded, setLoaded] = useState(true)
  const [isduedate, setIsduedate] = useState(orderItem.duedate)
  // const { currentCurrency, currencies } = useContext(CurrencyContext);
  const [userCurrency, setUserCurrency] = useState(user.currency)

  const handleCheckout = () => {
    if (orderItem.canCheckout) {
      setLoaded(false)
      firebase
        .checkoutOrderWithStripe(orderItem.Id, specDetails.currency)
        .then(() => {
          setLoaded(false)
        })
    }
  }


  useEffect(() => {
    // setUserCurrency(currentCurrency);
    if (isAdmin) {
      firebase
        .getAdminSpecDetailsById(orderItem.specificationId)
        .then(response => {
          if (response && response != null) {
            setSpecDetails(response)
          }
        })
    } else {
      firebase
        .getSpecificationDetailsById(orderItem.specificationId)
        .then(response => {
          if (response && response != null) setSpecDetails(response)
        })
    }
  }, [])


  const titleAddons = {
    shadow: "Shadow",
    invisibleMannequin: "Invisible Mannequin",
    clippingPath: "Clipping Path",
    colorMatching: "Color Fix",
    offmodel: "Apparel Off-Model",
    footwear: "Footwear",
    accessories: "Accessories",
    furniture: "Furniture",
    watch: "Jewellery & Watches",
    healthBeauty: "Cosmetics & Personal Care",
    general: "Others",
    model: "Apparel On-Model",
    apparel: "Apparel Off-Model",
    home: "Furniture",
    jewelry: "Jewellery & Watches",
    cosmetic: "Cosmetics & Personal Care",
    mannequin: "Apparel Off-Model",
    flats: "Apparel Off-Model",
  }

  var { additionalOutputList } = specDetails

  var additionalOutput = []

  if (additionalOutputList == undefined) {
    additionalOutput = []
  } else {
    additionalOutput = additionalOutputList[0]

  }



  const handleDownload = async () => {
    try {
      downloadImageAsZip(user, orderItem.Id, orderItem)

      if (pdfExportComponent.current) { pdfExportComponent.current.save() }
    } catch (error) {
      console.log("🚀 ~ file: OrderItem.js:96 ~ handleDownload ~ error:", error)
    }

  }





  // const handleUpload = () => {
  //   setModalShow(true);
  // };

  let history = useHistory()

  const handlePublish = () => {
    history.push(`/orders/details?orderId=${orderItem.Id}`)
  }

  const handleStartOrder = () => {
    orderItem.status = "In-Progress"

    firebase.updateOrderDetailsByAdmin(orderItem)
    // Send Email to user on Order Status update
    sendEmailUpdate()
  }

  // const closeModel = () => {
  //   setModalShow(false);
  // };

  const sendEmailUpdate = () => {
    const sendEmailOnOrderUpdate = firebase.functions.httpsCallable(
      "sendEmailOnOrderUpdate",
    )

    sendEmailOnOrderUpdate({
      userId: orderItem.userId,
      orderId: orderItem.Id,
      orderName: orderItem.name,
      orderStatus: orderItem.status,
    }).catch(e => {
      console.log(e)
    })
  }

  const DarkBackground = styled.div`
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    justify-content: center;
    flex-direction: column;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

    ${props =>
      props.disappear &&
      css`
        display: block; /* show */
      `}
  `
  //modal window of show and delete modal//
  const [isdelordermodal, setIsdelordermodal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleDeleteOrderModal = () => {
    setIsdelordermodal(true)
  }

  const handleCloseDeleteOrderModal = () => {
    setIsdelordermodal(false)
  }

  function handleDelete(orderId) {
    setLoading(true)
    firebase.deleteOrder(orderId).then(deletedOrder => {
      if (deletedOrder?.status === 200) {
        setLoading(false)
      }
    })
  }
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Order older than 14 days
    </Tooltip>
  )

  const now = moment()
  const daysSinceOrderCreation = now.diff(orderItem.createdDate, "days")

  const handleViewOrder = () => {
    history.push(`/orders/details?orderId=${orderItem.Id}`)
  }


  return (

    <>

      <tr
        className="text-center"
        style={{
          display: "grid",
          gridTemplateColumns: "4% 10% 10% 15% 16% 18% 18% 12% 18%",
          width: "85%",
        }}
      >
        <td style={{ whiteSpace: "nowrap" }}>{ordercount}</td>
        <td>
          <Link to={{ pathname: "/orders/details", search: "orderId=" + orderItem.Id, }}>
            {orderItem.Id}&nbsp;
            {daysSinceOrderCreation > 14 && (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <BsFillFlagFill style={{ color: "#9C27B0" }} />
              </OverlayTrigger>
            )}
          </Link>
        </td>
        {isAdmin && <>
          <td>


            <span className="text-dark-75 font-weight-bolder font-size-lg">
              {orderItem.userName}
            </span>
          </td>

          <td>
            <span className="text-dark-75 font-weight-bolder font-size-lg">
              {orderItem.email}
            </span>
          </td>
        </>}


        <td>
          <span className="text-dark-75 font-weight-bolder font-size-lg">
            {orderItem.name}
            {/* {orderItem.currency && orderItem.currency} ${" "}
          {orderItem.totalPrice}{" "}
          {specDetails.currency && specDetails.currency.toUpperCase()} */}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {orderItem.status === "Draft" ||
              orderItem.status === "Payment Pending"
              ? "NA"
              : moment(orderItem.createdDate)
                .format("dddd, MMMM Do YYYY, h:mm a")
                .toString()}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {orderItem.status === "Draft" ||
              orderItem.status === "Payment Pending"
              ? "NA"
              : orderItem.status === "Feedback Provided"
                ? moment(orderItem.dueDate)
                  .add(orderItem.turnAroundTime == 24 ? 12 : 6, "hours")
                  .format("dddd, MMMM Do YYYY, h:mm a")
                  .toString()
                : moment(orderItem.dueDate)
                  .format("dddd, MMMM Do YYYY, h:mm a")
                  .toString()}
          </span>
        </td>
        <td>
          <span
            style={{ width: 128, height: 32 }}
            className={`label label-lg font-weight-bolder label-light${orderStatus} label-inline`}
          >
            {orderItem.status === "Published" ? (
              <>
                {orderItem.status} &nbsp;
                <i
                  className="fa fa-check"
                  aria-hidden="true"
                  style={{ color: "green" }}
                ></i>
              </>
            ) : (
              orderItem.status
            )}
          </span>
        </td>

        {/* <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered0
      >
        <Modal.Body>
          <AdminUpload orderDetails={orderItem} closeModal={closeModel} />
        </Modal.Body>
      </Modal> */}

        {/* //delete order prompt model// */}
        <Modal
          show={isdelordermodal}
          onHide={() => setIsdelordermodal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ fontSize: "15px" }}>
              Are you sure you want to delete this order?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              className="btn btn-secondary float-right m-3 btn-lg"
              onClick={() => handleCloseDeleteOrderModal()}
            >
              Close
            </button>
            <button
              variant="primary"
              className="btn btn-primary float-right m-3 btn-lg"
              onClick={() => handleDelete(orderItem.Id)}
            >
              Delete
              {loading && <span className="ml-3 spinner spinner-black"></span>}
            </button>
          </Modal.Footer>
        </Modal>
        {!isAdmin ? (
          <td style={{ alignItems: "right" }}>
            {!hideCreate ? (
              <button
                className="btn btn-icon btn-light btn-sm mr-2"
                onClick={() => handleDeleteOrderModal()}
              >
                <DeleteSVG />
                {loading && <span className="ml-3 spinner spinner-black"></span>}
              </button>
            ) : null}

            {orderItem.status === "Draft" ? (
              <button
                className="btn btn-success"
                style={{ width: "100px", height: "fit-content" }}
                onClick={handlePublish}
              >
                Publish
              </button>
            ) : orderItem.status === "Payment Pending" ? (
              <button
                style={{ width: "100px", height: "fit-content" }}
                className="btn btn-success"
                onClick={handleViewOrder}
              >
                View Order
              </button>
            ) : (
              <button
                style={{ width: "100px", height: "fit-content" }}
                className="btn btn-success"
                onClick={handleViewOrder}
              >
                View Order
              </button>
            )}
          </td>
        ) : orderItem.status === "Published" ? (
          <td>
            <button
              style={{ height: "fit-content" }}
              className="btn btn-primary font-size-md"
              onClick={handleStartOrder}
            >
              {"Start Order"}
            </button>
          </td>
        ) : (
          <td>
            {/* <button
            className="btn btn-primary font-size-md"
            onClick={handleUpload}
          >
            {"Upload"}
          </button>{" "} */}
            <button
              className="btn btn-success font-size-md"
              onClick={handleDownload}
            >
              {"Download"}
            </button>
          </td>
        )}
        {/* {isAdmin ? (
        <td className="pr-0 text-right">
          <a className="btn btn-success font-size-md" onClick={handleUpload}>
            {"Upload"}
          </a>
        </td>
      ) : null}
      {isAdmin ? (
        <td className="pr-0 text-right">
          <a className="btn btn-success font-size-md" onClick={handleDownload}>
            {"Download"}
          </a>
        </td>
      ) : null} */}
        {!loaded && (
          <DarkBackground>
            <LoadingOverlay
              active={true}
              // spinner={<BounceLoader />}
              text="Taking you to the Payment Portal"
              spinner={true}
            ></LoadingOverlay>
          </DarkBackground>
        )}

      </tr>

      <div>
        {Object.keys(specDetails).length != 0 ? (
          <>
            <div style={{ position: "absolute", left: "-1000000px", top: 0, }}>
              <PDFExport
                fileName={orderItem.name + "_" + orderItem.Id}
                paperSize="A3"
                margin="0.5cm"
                ref={pdfExportComponent}
              >

                <img alt="logo" src="/media/logos/pixhoto-logo.svg"></img>
                <hr></hr>
                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item text-muted">USER DETAILS</li>
                  </ul>
                </div>
                <li>
                  <span className="float-left">
                    <strong>User Name:</strong>
                  </span>
                  <span className="float-right">{userName}</span>                
                </li>
                <li>
                  <span className="float-left">
                    <strong>Email Id:</strong>
                  </span>
                  <span className="float-right">{userEmail}</span>
                </li>
                <hr></hr>
                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item text-muted">ORDER DETAILS</li>
                  </ul>
                </div>
                <li>
                  <span className="float-left">
                    <strong>Order Name:</strong>
                  </span>
                  <span className="float-right">{orderItem.name}</span>
                </li>
                <li>
                  <span className="float-left">
                    <strong>Order Id:</strong>
                  </span>
                  <span className="float-right">{orderItem.Id}</span>
                </li>
                <li>
                  <span className="float-left">
                    <strong>Specification Name:</strong>
                  </span>
                  <span className="float-right">{specDetails.name}</span>
                </li>
                {specDetails.category && (
                  <li>
                    <span className="float-left">
                      <strong>Product Type:</strong>
                    </span>
                    <span className="float-right">
                      {titleAddons[specDetails.category]}
                    </span>
                  </li>
                )}
                {specDetails.category === "mannequin" ||
                  specDetails.category === "flats" ? (
                  <li>
                    <span className="float-left">
                      <strong>Photography Style:</strong>
                    </span>
                    <span className="float-right">
                      {specDetails.isManneqin ? "Mannequin / Hangers" : "Tabletop / Flats"}
                    </span>
                  </li>
                ) : null}

                {specDetails.brand && (
                  <li>
                    <span className="float-left">
                      <strong>Marketplace Brand Type:</strong>
                    </span>
                    <span className="float-right">{specDetails.brand}</span>
                  </li>
                )}
                <li>
                  <span className="float-left">
                    <strong>Order Creation Date:</strong>
                  </span>
                  <span className="float-right">
                    {moment(orderItem.createdDate).format("dddd, MMMM Do YYYY, h:mm a").toString()}
                  </span>
                </li>
                <li>
                  <span className="float-left">
                    <strong>Order Turnaround Time:</strong>
                  </span>
                  <span className="float-right">
                    {orderItem.turnAroundTime} Hours
                  </span>
                </li>

                <hr></hr>
                <div className="col-md-6">
                  <li className="list-group-item">
                    Specification Id: {specDetails.templateId}
                  </li>
                </div>

                <br></br>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>File Format</strong>
                        </span>
                        <span className="float-right">
                          {specDetails.settingUIData.fileType}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>Background</strong>
                        </span>
                        <span className="float-right">
                          {specDetails.settingUIData.backgroundType}
                        </span>
                      </li>
                      {specDetails.settingUIData.backgroundType === "RGB" ? (
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>BG Colour Details</strong>
                          </span>
                          <span className="float-right">
                            Hex: {specDetails.settingUIData.backgroundHex}
                            <br />
                            r: {specDetails.settingUIData.backgroundRStyle} g:{" "}
                            {specDetails.settingUIData.backgroundGStyle} b:{" "}
                            {specDetails.settingUIData.backgroundBStyle}
                          </span>
                        </li>
                      ) : null}
                      <li className="list-group-item">
                        {specDetails.settingUIData.size === "Aspect Ratio" ||
                          specDetails?.settingUIData?.size === "Original Dimensions" ? (
                          <span className="float-left">
                            <strong>Size</strong>
                          </span>
                        ) : (
                          <span className="float-left">
                            <strong>Min or Max Size</strong>
                          </span>
                        )}

                        {specDetails.settingUIData.size === "Aspect Ratio" ? (
                          <span className="float-right">
                            {specDetails.settingUIData.aspectratio}
                          </span>
                        ) : specDetails?.settingUIData?.size ===
                          "Original Dimensions" ? (
                          <span className="float-right">Original Dimensions</span>
                        ) : (
                          <span className="float-right">
                            {specDetails.settingUIData.minSizeInput} -{" "}
                            {specDetails.settingUIData.maxSizeInput} (
                            {specDetails.settingUIData.sizeUnit})
                          </span>
                        )}

                      </li>

                      {specDetails.settingUIData.aspectratio === "Custom" ? (
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>Aspect Size</strong>
                          </span>
                          <span className="float-right">
                            {specDetails.settingUIData.minSizeInput} :{" "}
                            {specDetails.settingUIData.maxSizeInput}
                          </span>
                        </li>
                      ) : null}

                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>Margins (L:R:T:B)</strong>
                        </span>
                        <span className="float-right">
                          {/* if all margins are zero, display margin as "Original" */}

                          {specDetails.settingUIData.marginLeft === 0 &&
                            specDetails.settingUIData.marginRight === 0 &&
                            specDetails.settingUIData.marginTop === 0 &&
                            specDetails.settingUIData.marginBottom === 0
                            ? "Original Margins"
                            : `${specDetails.settingUIData.marginLeft}:
              ${specDetails.settingUIData.marginRight}:
              ${specDetails.settingUIData.marginTop}:
              ${specDetails.settingUIData.marginBottom} (%)`}

                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>Alignment</strong>
                        </span>
                        <span className="float-right">
                          {specDetails.settingUIData.alignment}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>Colour Profile</strong>
                        </span>
                        <span className="float-right">
                          {specDetails.settingUIData.colorProfile}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>DPI</strong>
                        </span>
                        <span className="float-right">
                          {specDetails.settingUIData.dpi}
                        </span>
                      </li>
                      {specDetails.settingUIData.dpi === "Specify Dpi" ? (
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>DPI Value</strong>
                          </span>
                          <span className="float-right">
                            {specDetails.settingUIData.dpiValue}
                          </span>
                        </li>
                      ) : null}
                      <li className="list-group-item">
                        <span className="float-left">
                          <strong>Auto Rotate</strong>
                        </span>
                        <span className="float-right">
                          {specDetails.settingUIData.autoRotate ? "Yes" : "No"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item " style={{ color: "blue" }}>
                        ENHANCEMENT
                      </li>
                      <li className="list-group-item text-capitalize">
                        <span className="float-left">
                          <strong>Shadow</strong>
                        </span>
                        <span className="pl-12 float-right">
                          {" "}
                          {specDetails.addons.shadow}
                        </span>
                      </li>
                      <li className="list-group-item text-capitalize">
                        <span className="float-left">
                          <strong>Invisible Mannequin</strong>
                        </span>
                        <span className="pl-12 float-right">
                          {" "}
                          {specDetails.addons.invisibleMannequin}
                        </span>
                      </li>
                      <li className="list-group-item text-capitalize">
                        <span className="float-left">
                          <strong>Clipping Path</strong>
                        </span>
                        <span className="pl-12 float-right">
                          {" "}
                          {specDetails.addons.clippingPath}
                        </span>
                      </li>
                      <li className="list-group-item text-capitalize">
                        <span className="float-left">
                          <strong>Colour Fix</strong>
                        </span>
                        <span className="pl-12 float-right">
                          {specDetails.addons.colorMatching}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item " style={{ color: "blue" }}>
                        RETOUCHING
                      </li>

                      {specDetails.retouching.modelGarment === "None" &&
                        specDetails.retouching.modelRetouch === "None" &&
                        specDetails.retouching.retouch != "None" ? (
                        <li className="list-group-item text-capitalize">
                          <span className="float-left">
                            <strong>
                              {titleAddons[specDetails.retouching.retouchCategory]}
                            </strong>
                          </span>
                          <span className="pl-12 float-right">
                            {" "}
                            {specDetails.retouching.retouch}
                          </span>
                        </li>
                      ) : specDetails.retouching.modelGarment === "None" &&
                        specDetails.retouching.modelRetouch === "None" &&
                        specDetails.retouching.retouch === "None" ? (
                        <li className="list-group-item text-capitalize">
                          <span className="float-left">
                            <strong>No Retouch Options Selected</strong>
                          </span>
                        </li>
                      ) : null}

                      {specDetails.retouching.isRemoveLabel && (
                        <li className="list-group-item text-capitalize">
                          <span className="float-left">
                            <strong>Remove Label</strong>
                          </span>
                          <span className="pl-12 float-right">Yes</span>
                        </li>
                      )}
                      {specDetails.retouching.modelRetouch != "None" && (
                        <li className="list-group-item text-capitalize">
                          <span className="float-left">
                            <strong>Model Retouch</strong>
                          </span>
                          <span className="pl-12 float-right">
                            {" "}
                            {specDetails.retouching.modelRetouch}
                          </span>
                        </li>
                      )}
                      {specDetails.retouching.modelGarment != "None" && (
                        <li className="list-group-item text-capitalize">
                          <span className="float-left">
                            <strong>Apparel On Model Retouch</strong>
                          </span>
                          <span className="pl-12 float-right">
                            {" "}
                            {specDetails.retouching.modelGarment}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                {specDetails?.category == "model" && (
                  <div>
                    <div className="row">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item text-muted">CROPPING STYLE</li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {specDetails.cropping.fullBody.isFullBody && (
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Cropping Style</strong>
                              </span>
                              <span className="float-right">Full Body</span>
                            </li>
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Crop of Head</strong>
                              </span>
                              <span className="float-right">
                                {specDetails.cropping.fullBody.cropofHead}
                              </span>
                            </li>
                          </ul>
                        )}
                        {specDetails.cropping.top.isTop && (
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Cropping Style</strong>
                              </span>
                              <span className="float-right">Tops</span>
                            </li>
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Crop of Head</strong>
                              </span>
                              <span className="float-right">
                                {specDetails.cropping.top.cropofHead}
                              </span>
                            </li>
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Crop of Legs</strong>
                              </span>
                              <span className="float-right">
                                {specDetails.cropping.top.cropofLegs}
                              </span>
                            </li>
                          </ul>
                        )}
                        {specDetails.cropping.bottom.isBottom && (
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Cropping Style</strong>
                              </span>
                              <span className="float-right">Bottoms</span>
                            </li>
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Crop of Body</strong>
                              </span>
                              <span className="float-right">
                                {specDetails.cropping.bottom.cropofBody}
                              </span>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <br></br>
                  </div>
                )}
                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      ADDITIONAL OUTPUT
                    </li>
                  </ul>
                </div>

                {specDetails.additionalOutputList.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item " style={{ color: "blue" }}>
                          File Type and Background
                        </li>
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>File Format</strong>
                          </span>
                          <span className="float-right">
                            {additionalOutput.fileType}
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>Background</strong>
                          </span>
                          <span className="float-right">
                            {additionalOutput.backgroundType}
                          </span>
                        </li>
                        {additionalOutput.backgroundType === "RGB" ? (
                          <li className="list-group-item">
                            <span className="float-left">
                              <strong>BG Colour Details</strong>
                            </span>
                            <span className="float-right">
                              Hex: {additionalOutput.backgroundHex}
                              <br />
                              r: {additionalOutput.backgroundRStyle} g:{" "}
                              {additionalOutput.backgroundGStyle} b:{" "}
                              {additionalOutput.backgroundBStyle}
                            </span>
                          </li>
                        ) : null}

                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>Margins (L:R:T:B)</strong>
                          </span>
                          <span className="float-right">
                            {additionalOutput.marginLeft === 0 &&
                              additionalOutput.marginRight === 0 &&
                              additionalOutput.marginTop === 0 &&
                              additionalOutput.marginBottom === 0
                              ? "Original Margins"
                              : `${additionalOutput.marginLeft}:
              ${additionalOutput.marginRight}:
              ${additionalOutput.marginTop}:
              ${additionalOutput.marginBottom} (%)`}
                          </span>
                        </li>
                        <br></br>
                      </ul>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item " style={{ color: "blue" }}>
                          Cropping and Alignment
                        </li>
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>Size</strong>
                          </span>
                          <span className="float-right">{additionalOutput.size}</span>
                        </li>
                        {additionalOutput.size === "Aspect Ratio" ? (
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <span className="float-left">
                                <strong>Aspect Size</strong>
                              </span>
                              <span className="float-right">
                                {additionalOutput.aspectratio}
                              </span>
                            </li>
                            {additionalOutput.aspectratio === "Custom" ? (
                              <li className="list-group-item">
                                <span className="float-left">
                                  <strong>Custom Ratio</strong>
                                </span>
                                <span className="float-right">
                                  {additionalOutput.minSizeInput} :
                                  {additionalOutput.maxSizeInput}
                                </span>
                              </li>
                            ) : null}
                          </ul>
                        ) : null}
                        {additionalOutput.size === "Exact Size" ? (
                          <li className="list-group-item">
                            <span className="float-left">
                              <strong>Exact Size</strong>
                            </span>
                            <span className="float-right">
                              Width: {additionalOutput.minSizeInput} Height:{" "}
                              {additionalOutput.maxSizeInput}
                            </span>
                          </li>
                        ) : null}
                        {additionalOutput.size === "Exact Size" ||
                          additionalOutput.size === "Aspect Ratio" ? (
                          <li className="list-group-item">
                            <span className="float-left">
                              <strong>Vertical Alignment</strong>
                            </span>
                            <span className="float-right">
                              {additionalOutput.size === "Trim(Crop tightly)" ||
                                additionalOutput.size ===
                                "Keep original aspect & margin"
                                ? "None"
                                : additionalOutput.alignment}
                            </span>
                          </li>
                        ) : null}
                      </ul>

                      <ul className="list-group list-group-flush">
                        <li className="list-group-item " style={{ color: "blue" }}>
                          Other Settings
                        </li>
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>File name</strong>
                          </span>
                          <span className="float-right">
                            {additionalOutput.postfix}
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>Max Output File Size</strong>
                          </span>
                          <span className="float-right">
                            {additionalOutput.maxFileSizeType}
                          </span>
                        </li>
                        {additionalOutput.maxFileSizeType === "Specify" ? (
                          <li className="list-group-item">
                            <span className="float-left">
                              <strong>Maximun Size</strong>
                            </span>
                            <span className="float-right">
                              {additionalOutput.maxFileSize} kb
                            </span>
                          </li>
                        ) : null}
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>DPI</strong>
                          </span>
                          <span className="float-right">{additionalOutput.dpi}</span>
                        </li>
                        {additionalOutput.dpi === "Specify Dpi" ? (
                          <li className="list-group-item">
                            <span className="float-left">
                              <strong>DPI Value</strong>
                            </span>
                            <span className="float-right">
                              {additionalOutput.dpiValue}
                            </span>
                          </li>
                        ) : null}
                        <li className="list-group-item">
                          <span className="float-left">
                            <strong>Colour Profile</strong>
                          </span>
                          <span className="float-right">
                            {additionalOutput.colorProfile}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <span className="float-left">
                        <strong>No Additional Output details provided</strong>
                      </span>
                    </div>
                  </div>
                )}

                <hr></hr>
                <br></br>
                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      IMAGE DETAILS
                    </li>
                  </ul>
                </div>
                <div>


                  {annotationList.length ? (
                    <table
                      responsive="lg"
                      width="100%"
                      style={{ borderCollapse: "collapse", margin: "1em 0" }}
                    >
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "80%" }} />
                      <thead>
                        <tr>
                          <th className="th">Image Name</th>
                          <th className="th">Additional Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderItem?.images?.map(image => {
                          const annotationTagList = image.tags.filter(
                            tag => tag.tagName === "annotationTag",
                          )
                          return (
                            <tr key={image.imageName + "." + image.imageExtension}>
                              {image.tags.find(
                                tag => tag.tagName === "annotationTag",
                              ) && (
                                  <>
                                    <td className="td">
                                      {image.imageName + "." + image.imageExtension}
                                    </td>
                                    <td className="td">
                                      {annotationTagList
                                        .map(annotationTag => annotationTag.title)
                                        .join(", ")}
                                    </td>
                                  </>
                                )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : (
                    "No Image Annotations were added"
                  )}

                </div>
                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      IMAGES BY GROUPS
                    </li>
                  </ul>
                </div>

                {!groupTagList.length ? (
                  "No Group Tags Were Added"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <thead>
                      <tr>
                        <th className="th">Group Name</th>
                        <th className="th">Image Names</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupTagList.map(group => {
                        return (
                          <tr>
                            <td className="td">{group}</td>
                            <td className="td">
                              {" "}
                              {orderItem.images
                                .filter(image =>
                                  image["tags"].find(tag => tag.title === group),
                                )
                                .map(
                                  image =>
                                    image.imageName + "." + image.imageExtension,
                                )
                                .join(", ")}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      REFERENCE IMAGES
                    </li>
                  </ul>
                </div>

                {!referenceTagList.length ? (
                  "No Image Reference Tags Were Added"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <thead>
                      <tr>
                        <th className="th">Image Reference</th>
                        <th className="th">Image Names</th>
                      </tr>
                    </thead>
                    <tbody>
                      {referenceTagList.map(ref => {
                        return (
                          <tr>
                            <td className="td">{ref}</td>
                            <td className="td">
                              {" "}
                              {orderItem.images
                                .filter(image =>
                                  image["tags"].find(
                                    tag =>
                                      tag.title === ref &&
                                      tag.tagName === "referenceTag",
                                  ),
                                )
                                .map(
                                  image =>
                                    image.imageName + "." + image.imageExtension,
                                )
                                .join(", ")}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      IMAGES BY COLOR SWATCH
                    </li>
                  </ul>
                </div>

                {!colorTagList.length ? (
                  "No Color Ref Tags Were Added"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <thead>
                      <tr>
                        <th className="th">Image Reference</th>
                        <th className="th">Image Names</th>
                      </tr>
                    </thead>
                    <tbody>
                      {colorTagList.map(colorTag => {
                        return (
                          <tr>
                            <td className="td">{colorTag}</td>
                            <td className="td">
                              {" "}
                              {orderItem.images
                                .filter(image =>
                                  image["tags"].find(
                                    tag => tag.value === colorTag.split(" (#")[0],
                                  ),
                                )
                                .map(
                                  image =>
                                    image.imageName + "." + image.imageExtension,
                                )
                                .join(", ")}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      IMAGES BY EXTERNAL LINK FOR COLOR FIX
                    </li>
                  </ul>
                </div>

                {!externalColorTagList.length ? (
                  "No External Color Ref Tags Were Added"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "60%" }} />

                    <thead>
                      <tr>
                        <th className="th">External Reference</th>
                        <th className="th">Image Names</th>
                      </tr>
                    </thead>
                    <tbody>
                      {externalColorTagList.map(externalTag => {
                        return (
                          <tr>
                            <td className="td">{externalTag}</td>
                            <td className="td">
                              {" "}
                              {orderItem.images
                                .filter(image =>
                                  image["tags"].find(
                                    tag =>
                                      tag.value + " (" + tag.title + ")" ===
                                      externalTag,
                                  ),
                                )
                                .map(
                                  image =>
                                    image.imageName + "." + image.imageExtension,
                                )
                                .join(", ")}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      IMAGES BY COLOR WAYS
                    </li>
                  </ul>
                </div>

                {!colorWaysTagList.length ? (
                  "No ColorWays Tags Were Added"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <thead>
                      <tr>
                        <th className="th">ColorWays Ref</th>
                        <th className="th">Image Names</th>
                      </tr>
                    </thead>
                    <tbody>
                      {colorWaysTagList.map(color => {
                        return (
                          <tr>
                            <td className="td">{color}</td>
                            <td className="td">
                              {" "}
                              {orderItem.images
                                .filter(image =>
                                  image["tags"].find(
                                    tag => tag.value === color.split(" (#")[0],
                                  ),
                                )
                                .map(
                                  image =>
                                    image.imageName + "." + image.imageExtension,
                                )
                                .join(", ")}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      IMAGES BY INVISIBLE MANNEQUIN
                    </li>
                  </ul>
                </div>

                {!mannequinTagList.length ? (
                  "No Invisible Mannequin Tags Were Added"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <thead>
                      <tr>
                        <th className="th">Invisible Mannequin Ref</th>
                        <th className="th">Image Names</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mannequinTagList.map(manne => {
                        return (
                          <tr>
                            <td className="td">{manne}</td>
                            <td className="td">
                              {" "}
                              {orderItem.images
                                .filter(image =>
                                  image["tags"].find(tag => tag.title === manne),
                                )
                                .map(
                                  image =>
                                    image.imageName + "." + image.imageExtension,
                                )
                                .join(", ")}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      ORDER FEEDBACK
                    </li>
                  </ul>
                </div>
                {!orderItem.orderFeedback ? (
                  "No Order Feedback Provided"
                ) : (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <tbody>
                      <tr>
                        <td className="td">Feedback</td>
                        <td className="td">{orderItem.orderFeedback}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <div className="row">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item " style={{ color: "blue" }}>
                      ORDER REMARKS
                    </li>
                  </ul>
                </div>
                {orderItem.orderRemarks ? (
                  <table
                    responsive="lg"
                    width="100%"
                    style={{ borderCollapse: "collapse", margin: "1em 0" }}
                  >
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "80%" }} />

                    <tbody>
                      <tr>
                        <td className="td">Remarks</td>
                        <td className="td">{orderItem.orderRemarks}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  "No Order Remarks Provided"
                )}
              </PDFExport>
            </div>
          </>
        ) : null}
      </div>

    </>
  )


}
