import React, { useEffect, useState } from "react"
import * as specTemplate from "../../../app/modules/Specifications/__mocks__/data.json"
import objectDataCopy from "../../../app/modules/Specifications/__mocks__/data_copy"

const specificationContext = React.createContext()

function MetronicSpecificationProvider({ children }) {
  const specificationDetails = JSON.parse(
    localStorage.getItem("specificationToEdit"),
  )

  const [specificationToEdit, setSpecificationToEdit] = useState(
    specificationDetails ? specificationDetails : objectDataCopy(),
  )

  return (
    <specificationContext.Provider
      value={{ specificationToEdit, setSpecificationToEdit }}
    >
      {children}
    </specificationContext.Provider>
  )
}

export { specificationContext, MetronicSpecificationProvider }
