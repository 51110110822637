import React, { useState, useEffect } from "react"
import "./styles/core.css"
import "./styles/dashboard.css"
import firebase from "../../firebase/firebase"
import { useSelector, shallowEqual } from "react-redux"
import { Modal, Button } from "react-bootstrap"
import { ModalProgressBar } from "../../../_metronic/_partials/controls"
import imageCompression from "browser-image-compression"

const Uppy = require("@uppy/core")
const Tus = require("@uppy/tus")
const { Dashboard, useUppy } = require("@uppy/react")

export function AdminUpload({ orderDetails, closeModal }) {
  const user = useSelector(state => state.auth.user, shallowEqual)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [loading, setloading] = useState(false)

  const closeModalWindow = () => {
    closeModal()
  }
  const uppy = useUppy(() => {
    return new Uppy({
      debug: false,
      autoProceed: false,
      restrictions: {
        maxFileSize: 100000000000,
        maxNumberOfFiles: 250,
        minNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".gif", ".tiff", ".psd"],
      },
    })
  })

  useEffect(() => {
    // uppy.use(Tus, { endpoint: "https://tusd.tusdemo.net/files" });
  }, [])

  uppy.on("complete", result => {
    uploadImages(result.successful)
  })
  // uppy.on("file-removed", (file, reason) => {
  //   if (reason === "removed-by-user") {
  //   }
  // });

  // uppy.on("file-added", (file) => {
  //   const data = file.data;
  //   const url = data.thumbnail ? data.thumbnail : URL.createObjectURL(data);
  //   const image = new Image();
  //   image.src = url;
  //   image.onload = () => {
  //     uppy.setFileMeta(file.id, { width: image.width, height: image.height });
  //     URL.revokeObjectURL(url);
  //   };
  //   image.onerror = () => {
  //     URL.revokeObjectURL(url);
  //   };
  // });

  const getVersionNumber = (orderDetails, fileName) => {
    let imageSize = orderDetails.images.filter(
      image => image.imageName + "." + image.imageExtension === fileName,
    )

    if (imageSize.length == 0) {
      return 1
    } else {
      imageSize.sort((a, b) => b.version - a.version)
      //the first obj will have the highest version
      return imageSize[0].version + 1
    }
    //return imageSize.length==0?1:imageSize.length;
    // var adminUploadedImages = [];
    // orderDetails.images.forEach((image) => {
    //   if (image["tags"].find((obj) => obj.tagName === "adminUploadTag")) {
    //     adminUploadedImages.push(image);
    //   }
    // });
    // if (adminUploadedImages.length == 0) return 1;
    // else {
    //   adminUploadedImages.sort((a, b) => b.version - a.version);
    //   //the first obj will have the highest version
    //   return adminUploadedImages[0].version + 1;
    // }
  }

  async function versionizeAndUpload(orderDetails) {
    var timeStamp = new Date().getTime()
    var count = 0
    uploadedFiles.forEach(async file => {
      let uploadError = false
      // await firebase.db
      //   .collection(firebase.USER_COLLECTION)
      //   .doc(orderDetails.userId)
      //   .collection(firebase.USER_IMG_LIB)
      //   .where("imageName", "==", file.meta.name)
      //   .get()
      //   .then(async (response) => {
      // const size = response.size;
      // const version = size + 1;
      //get filename without the extension
      // let fileName = file.meta.name.replace(`.${file.extension}`, "");
      // var imageName = fileName + "_V" + version + "." + file.extension;
      var imageName = file.meta.name.replace(`.${file.extension}`, "")
      let version = getVersionNumber(orderDetails, file.meta.name)
      const storageRef = firebase.storage.ref(
        `${"Image Library"}/${orderDetails.userId}/${"Orders"}/${
          orderDetails.Id
        }/${timeStamp}/${file.meta.name}`,
      )
      await storageRef.put(file.data).catch(error => {
        uploadError = true
        console.log(error)
      })
      const imgURL = await storageRef.getDownloadURL().catch(error => {
        uploadError = true
        console.log(error)
      })
      const imageTags = [
        {
          tagName: "adminUploadTag",
          title: "Updated Image",
          value: `Edit Version ${version}`,
        },
      ]
      const isReferenceImage = file.meta?.isReferenceImage || "off"
      const colorReference = file.meta?.colorReference || ""

      let thumbnailURL
      if (
        file.extension !== "gif" &&
        file.extension !== "tif" &&
        file.extension !== "tiff" &&
        file.extension !== "psd"
      ) {
        let options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 400,
          fileType: file.extension,
        }
        const thumbnailFile = await imageCompression(file.data, options).catch(
          error => {
            uploadError = true
            console.log(error)
          },
        )
        const thumbnailRef = firebase.storage.ref(
          `${"Image Library"}/${orderDetails.userId}/${"Orders"}/${
            orderDetails.Id
          }/${timeStamp}/${"Thumbnail"}/${file.meta.name}`,
        )
        await thumbnailRef.put(thumbnailFile).catch(error => {
          uploadError = true
          console.log(error)
        })
        thumbnailURL = await thumbnailRef.getDownloadURL().catch(error => {
          uploadError = true
          console.log(error)
        })
      } else if (file.extension === "gif") {
        thumbnailURL = imgURL
      } else {
        thumbnailURL =
          "https://firebasestorage.googleapis.com/v0/b/pixhoto-prod.appspot.com/o/Common%20Images%2Fno-thumbnail.jpg?alt=media&token=01de0070-b8bc-4f04-aec0-6d3720c24108"
      }

      //load default thumbnail for tiff and psd
      // const thumbnail = file.extension == "tif" || file.extension == "tiff" || file.extension == "psd"?
      // "https://firebasestorage.googleapis.com/v0/b/pixhoto-prod.appspot.com/o/Common%20Images%2Fno-thumbnail.jpg?alt=media&token=01de0070-b8bc-4f04-aec0-6d3720c24108"
      // :imgURL;
      const image = {
        id: "0",
        src: imgURL,
        thumbnail: thumbnailURL,
        imageName: imageName,
        imageExtension: file.extension,
        thumbnailWidth: file.meta.width ? file.meta.width : 585,
        thumbnailHeight: file.meta.heigh ? file.meta.heigh : 390,
        version: version,
        caption: imageName,
        tags: imageTags,
        isReferenceImage,
        colorReference,
        annotations: [],
        orderId: orderDetails.Id,
      }
      await firebase.db
        .collection(firebase.USER_COLLECTION)
        .doc(orderDetails.userId)
        .collection(firebase.USER_IMG_LIB)
        .add(image)
        .then(response => {
          image.id = response.id
          orderDetails.images.push(image)

          count++
        })
        .catch(e => {
          console.log(e)
        })

      if (count == uploadedFiles.length) {
        orderDetails.updatedImageTimestamp = timeStamp
        orderDetails.status = "Ready"
        firebase.updateOrderDetailsByAdmin(orderDetails).then(response => {
          //Orderupdated
          setloading(false)
          closeModalWindow()
          sendEmailUpdate(orderDetails, count)
        })
      }
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
      // if (count == uploadedFiles.length) {
      //   setloading(false);
      //   closeModalWindow();
      //   sendEmailUpdate(orderDetails,count);
      // }
    })
  }

  const sendEmailUpdate = (orderDetails, count) => {
    //console.log("Outside logic",orderDetails);
    firebase
      .getUserDetailsById(orderDetails.userId)
      .then(response => {
        let timeStamp = new Date().getTime()
        const functionDetails = {
          email: response.email,
          orderId: orderDetails.Id,
          userName: response.firstname,
          type: "orderReady",
          imageCount: count,
          userId: orderDetails.userId,
          emailSentTime: timeStamp,
        }
        //console.log("Inside logic");
        const sendOrderRelatedEmails = firebase.functions.httpsCallable(
          "sendOrderRelatedEmails",
        )

        sendOrderRelatedEmails({
          email: response.email,
          orderId: orderDetails.Id,
          userName: response.firstname,
          type: "orderReady",
          imageCount: count,
        })
          .then(() => {
            //console.log("Inside logic 2");
            firebase.createFunctionCall(functionDetails)
            window.location.reload()
          })
          .catch(e => {
            console.log(e)
          })
      })
      .catch(e => {
        console.log(e)
      })
  }

  const uploadImages = files => {
    //uploadedFiles.splice(0, uploadedFiles.length);
    uppy.getFiles().forEach(async file => {
      if (!uploadedFiles.find(obj => obj.meta.name === file.meta.name))
        uploadedFiles.push(file)
      uppy.setFileState(file.id, {
        progress: { uploadComplete: true, uploadStarted: true },
      })
      uppy.setState({
        totalProgress: Math.floor(
          100 * (uploadedFiles.length / uppy.getFiles().length),
        ),
      })
    })
  }

  return (
    <div>
      {loading && <ModalProgressBar />}
      <Dashboard
        uppy={uppy}
        plugins={[]}
        width="100%"
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails={true}
        autoProceed={false}
        showRemoveButtonAfterComplete={false}
        waitForThumbnailsBeforeUpload={false}
        showLinkToFileUploadResult={false}
        metaFields={[{ id: "name", name: "Name", placeholder: "file name" }]}
        doneButtonHandler={() => {
          setloading(true)
          let doneBtn = document.getElementsByClassName(
            "uppy-StatusBar-actions",
          )
          doneBtn[0].style.display = "none"
          versionizeAndUpload(orderDetails)
        }}
        closeModalOnClickOutside={true}
        locale={{ strings: { done: "Update Order" } }}
        hideAfterFinish={true}
      />
    </div>
  )
}
